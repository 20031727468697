/**Component for listing all buses that are available in the system */
import React, { useState, useTransition } from 'react';
import { Button, Form, Input, Modal, Select, Table } from 'antd';
import { Query } from '@apollo/react-components';
import { FETCH_VEHICLES, UpdateBusResource } from 'services';
import { ListHeader, PrimaryTitle } from 'components';
import { useMutation } from '@apollo/react-hooks';
import { notify } from '../../utilities';
import { useTranslation } from 'react-i18next';

const BusClassList = () => {
  const { Item } = Form;
  const [form] = Form.useForm();
  const { Option, OptGroup } = Select;
  const [t, i18n] = useTranslation();
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Reference Code',
      dataIndex: 'ref_code',
      key: 'ref_code',
    },
    {
      title: 'Type',
      dataIndex: ['bus_type', 'name'],
      key: 'bus_type.name',
    },
    {
      title: 'Resource',
      dataIndex: ['resource'],
      key: 'bus_type.resource',
    },
    {
      title: 'License Number',
      dataIndex: 'licence_no',
      key: 'licence_no',
    },
    {
      title: 'Mileage',
      dataIndex: 'mileage_no',
      key: 'mileage_no',
    },
    {
      title: 'Update',
      key: (bus) => {
        return 'action' + bus.id;
      },

      render: (bus) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setBus(bus);
              setEditResource(true);
            }}
          >
            {t('Set Resource')}
          </Button>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });
  const [bus, setBus] = useState(null);
  const [
    Update_Bus_Resource,
    { loading: UpdateBusResourceLoading },
  ] = useMutation(UpdateBusResource);
  const [editResourceVisible, setEditResource] = useState(false);
  const BusResources = ['JABAL BUS'];
  const onCancel = () => {
    setEditResource(false);
    setBus(null);
    form.resetFields();
  };
  const submitEditResource = () => {
    form.validateFields().then((values) => {
      const { resource } = values;
      Update_Bus_Resource({
        variables: {
          id: bus.id,
          resource: resource,
        },
      })
        .then((response) => {
          const res = response?.data?.updateBusResource;
          console.log(res);
          if (res.status === true) {
            notify('success', res.message);
            window.location.reload();
          } else {
            notify('error', 'some thing went wrong');
          }
          setEditResource(false);
          form.resetFields();
        })
        .catch((err) => {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];

          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', t(message));
          }
        });
    });
  };

  return (
    <>
      <Modal
        visible={editResourceVisible}
        title={t('Edit Bus# ') + bus?.id}
        okText={t('Ok')}
        cancelText={t('Cancel')}
        onCancel={onCancel}
        onOk={submitEditResource}
        confirmLoading={UpdateBusResourceLoading}
      >
        <Form name="update_bus_resource" form={form}>
          <Item
            style={{ padding: '20px 0', margin: 0 }}
            name="resource"
            label={t("Resource")}
            rules={[
              {
                required: true,
                message: t('Please, select a resource!'),
              },
            ]}
          >
            <Select style={{ width: 200 }} defaultValue={t("select a resource")}>
              {BusResources.map((resource, idx) => (
                <Option key={idx} value={resource}>
                  {resource}
                </Option>
              ))}
            </Select>
          </Item>
        </Form>
      </Modal>

      <Query query={FETCH_VEHICLES}>
        {({ loading, data, refetch }) => (
          <>
            {loading && <p>Loading ...</p>}
            {data && data.allBusses && (
              <>
                <ListHeader>
                  <PrimaryTitle>{t('Bus Vehicles')}</PrimaryTitle>
                </ListHeader>
                <Table
                  scroll={{ x: 400 }}
                  dataSource={data.allBusses}
                  columns={columns}
                  pagination={false}
                />
              </>
            )}
          </>
        )}
      </Query>
    </>
  );
};

export default BusClassList;
