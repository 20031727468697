import gql from 'graphql-tag';

export const GET_AGENTS = gql`
  query agents($first: Int!, $page: Int) {
    agents(first: $first, page: $page) {
      paginatorInfo {
        count
        total

        currentPage
      }
      data {
        id
        user_name
        totals
        credit_balance
        enable_borrowing
      }
    }
  }
`;

export default GET_AGENTS;
