/**Component for listing all available cities in the system
 * listing contain actions for editing or deactivating
 * a current city
 */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { StarOutlined } from '@ant-design/icons';
import { Table, Divider, Popconfirm } from 'antd';
import { Mutation } from '@apollo/react-components';
import { GET_CITIES, DEACTIVATE_CITY, ACTIVATE_CITY } from 'services';
import { ListHeader, PrimaryButton, PrimaryTitle, Can } from 'components';
import { useHistory } from 'react-router-dom';
import { notify } from '../../utilities';
import { useTranslation } from 'react-i18next';

const LocationsList = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const [page, handlePageChange] = useState(1);
  const { data, loading, error, fetchMore } = useQuery(GET_CITIES, {
    variables: {
      page: 1,
      first: 5,
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const handleError = (err) => {
    console.log(err);
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Seasonal',
      dataIndex: 'is_seasonal',
      render: (is_seasonal) => {
        return is_seasonal === 'Active' && <StarOutlined></StarOutlined>;
      },
    },
    {
      title: 'English Name',
      dataIndex: 'name_en',
      key: 'name_en',
    },
    {
      title: 'Arabic Name',
      dataIndex: 'name_ar',
      key: 'name_ar',
    },
    {
      title: 'Active Statue',
      // dataIndex: 'is_active',
      key: 'is_active',
      render: (record)=> t(record.is_active),
    },
    {
      title: '',
      render: (city) => {
        const isActiveCity = city.is_active === 'Active';
        return (
          <>
            <Can
              perform="UPDATE_CITY"
              yes={<a href={`/edit-city/${city.id}`}>{t('Edit')}</a>}
            />

            <Divider type="vertical" />
            {
              /**Activating or deactivating functionality based on city current status */
              <Can
                perform="UPDATE_CITY"
                yes={
                  <Mutation
                    onError={(error) => {
                      if (error) {
                        if (error['graphQLErrors'][0]?.extensions) {
                          const {
                            extensions: { validation },
                            message,
                          } = error['graphQLErrors'][0];
                          if (validation) {
                            for (let error in validation) {
                              notify('error', validation[error][0]);
                            }
                          }
                          notify('error', message);
                        }
                      }
                    }}
                    mutation={isActiveCity ? DEACTIVATE_CITY : ACTIVATE_CITY}
                    variables={{ id: Number(city.id) }}
                    refetchQueries={() => [
                      {
                        query: GET_CITIES,
                        variables: {
                          page: page,
                          first: 5,
                        },
                      },
                    ]}
                  >
                    {(mutationFunc, { loading, error }) => {
                      return (
                        <>
                          {loading && <p>{loading}</p>}

                          <Popconfirm
                            title={
                              isActiveCity
                                ? t('Sure to Deactivate?')
                                : t('Sure to Activate?')
                            }
                            onConfirm={mutationFunc}
                          >
                            <a href="#!">
                              {isActiveCity ? t('Deactivate') : t('Activate')}
                            </a>
                          </Popconfirm>
                        </>
                      );
                    }}
                  </Mutation>
                }
              />
            }
          </>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });
  return (
    <>
      <ListHeader>
        <PrimaryTitle>{t('Cities')}</PrimaryTitle>
        <Can
          perform="CREATE_CITY"
          yes={
            <PrimaryButton
              onClick={() => {
                history.push('/new-city');
              }}
            >
              {t('Add New')}
            </PrimaryButton>
          }
        />
      </ListHeader>
      <Table
        scroll={{ x: 400 }}
        dataSource={
          data && data.cities
            ? data.cities.data.map((city) => ({
                ...city,
                key: city.code,
              }))
            : []
        }
        columns={columns}
        loading={loading}
        pagination={{
          total:
            data &&
            data.cities &&
            data.cities.paginatorInfo &&
            data.cities.paginatorInfo.total,
          pageSize: 5,
          onChange: (page) => {
            handlePageChange(page);
            fetchMore({
              variables: {
                page: page,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
              },
            });
          },
        }}
      />
    </>
  );
};

export default LocationsList;