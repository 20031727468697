import gql from 'graphql-tag';

export const FetchTrips = gql`
  query tripTemplate($tripTemplateId: ID!, $first: Int!, $page: Int!) {
    tripTemplate(id: $tripTemplateId) {
      id
      ref_code
      access_level
      from_date
      to_date
      is_active
      routeLines {
        id
        from_city {
          id
          code
          name_en
          name_ar
        }
        to_city {
          id
          code
          name_en
          name_ar
        }
        prices {
          id
          seat_type_id {
            id
            name_en
            name_ar
          }
          bus_salon_id
          price
        }
      }
      timeLines {
        timeLineSlots {
          time
          day
          location {
            name_en
            name_ar
          }
        }
      }
      trips(first: $first, page: $page) {
        paginatorInfo {
          hasMorePages
          total
        }
        data {
          id
          ref_code
          date
          time
          is_active
          has_shipping
          seats_tickets {
            id
            status
            code
            trip {
              id
              ref_code
              date
              time
              updated_at
            }
            id
            routeLine {
              id
            }
            seat_number
            from_location {
              id
              name_en
              name_ar
              city {
                id
                name_en
                name_ar
              }
            }
            to_location {
              id
              name_en
              name_ar
              city {
                id
                name_en
                name_ar
              }
            }
            seat_type {
              id
              name_en
              name_ar
            }
            price
            customer {
              id
              name
              phone
              registered
            }
          }
          busSalon {
            id
            name
          }

          routeLines {
            id
            bus {
              id
              ref_code
              licence_no
              mileage_no
            }
            shipments {
              id
              order_number
              sender {
                id
                name
                phone
                national_id
              }
              receiver {
                id
                name
                phone
                national_id
              }
              from_location {
                id
                name_en
                name_ar
              }
              to_location {
                id
                name_en
                name_ar
              }
              trip_route_line {
                id
                trip {
                  id
                  ref_code
                  date
                  time
                }
                from_city {
                  id
                  name_en
                  name_ar
                }
                to_city {
                  id
                  name_en
                  name_ar
                }
              }
              status {
                value
                label
              }
              total_price
              items {
                id
                name
                price
                initial_weight
                extra_weight
                quantity
                category {
                  id
                  name
                }
              }
            }
            drivers {
              id
              name
              phone
            }
          }
        }
      }
      created_at
      updated_at
    }
  }
`;
