/**Contain all available routes in the system with permission to allow/not allow
 * access to these routes
 */

const routes = [
  {
    name: 'Home',
    path: '/',
    permission: null,
  },
  {
    name: 'System Logs',
    path: '/logs',
    permission: 'LIST_LOGS',
  },
  {
    name: 'Trips',
    subItems: [
      {
        name: 'Trip Templates',
        path: '/trips',
        permission: 'LIST_TRIP_TEMPLATES',
      },
      {
        name: 'Trip Instances',
        path: '/trip-instances',
        permission: 'LIST_TRIP_INSTANCES',
      },
      {
        name: 'Private Trips',
        path: '/trip-private',
        permission: 'LIST_PRIVATE_TRIPS',
      },
    ],
    permission: 'VIEW_TRIPS_TAB',
  },
  {
    name: 'Tickets',
    path: '/tickets',
    permission: 'LIST_TICKETS',
  },
  {
    name: 'Book a ticket',
    path: '/ticketing',
    permission: 'CREATE_ORDER',
  },
  {
    name: 'Buses',
    subItems: [
      {
        name: 'Vehicles',
        path: '/vehicles',
        permission: 'LIST_BUSES',
      },
      {
        name: 'Salons',
        path: '/salons',
        permission: 'LIST_SALONS',
      },
    ],
    permission: 'VIEW_BUSES_TAB',
  },
  {
    name: 'Locations',
    subItems: [
      {
        name: 'Cities',
        path: '/cities',
        permission: 'LIST_CITIES',
      },
      {
        name: 'Stations',
        path: '/stations',
        permission: 'LIST_STATIONS',
      },
    ],
    permission: 'VIEW_LOCATIONS_TAB',
  },
  {
    name: 'Customers',
    path: '/customers',
    permission: 'LIST_CUSTOMERS',
  },

  {
    name: 'Drivers',
    subItems: [
      {
        name: 'Drivers List',
        path: '/drivers',
        permission: 'LIST_DRIVERS',
      },
      {
        name: 'Cash Collection',
        path: '/cash-collection',
        permission: 'LIST_CASHOUT',
      },
    ],
    permission: 'LIST_DRIVERS',
  },

  {
    name: 'Orders',
    path: '/orders',
    permission: 'LIST_ORDERS',
  },
  {
    name: 'Promo Codes',
    path: '/promo-codes',
    permission: 'LIST_PROMO_CODES',
  },
  {
    name: 'Notifications',
    path: '/notifications',
    permission: 'LIST_NOTIFICATIONS',
  },
  {
    name: 'Staff',
    path: '/staff',
    permission: 'LIST_USERS',
  },
  {
    name: 'Shifts',
    subItems: [
      {
        name: 'Station Shifts',
        path: '/shifts',
        permission: 'LIST_STATION_SHIFTS',
      },
      {
        name: 'Shifts Logs',
        path: '/shifts-logs',
        permission: 'LIST_SHIFTS_LOGS',
      },
    ],
    permission: 'VIEW_SHIFT_TAB',
  },
  {
    name: 'Customer Cashout',
    path: '/search-cashout',
    permission: 'LIST_CUSTOMER_CASHOUT',
  },
  {
    name: 'Cash_1',
    subItems: [
      {
        name: 'Cashout',
        path: '/cashout',
        permission: 'LIST_CASHOUT',
      },
      {
        name: 'Other Fees',
        path: '/other-fees',
        permission: 'LIST_CASHOUT',
      },
    ],
    permission: 'LIST_CASHOUT',
  },
  {
    name: 'Partners Segments',
    path: '/segments',
    permission: 'LIST_PARTNER_SEGMENT',
  },
  {
    name: 'Agent',
    path: '/agents',
    permission: 'LIST_PARTNER_SEGMENT',
  },
  {
    name: 'Permissions',
    path: '/permissions',
    permission: 'LIST_ALL_PERMISSION',
  },
  {
    name: 'Roles',
    path: '/roles',
    permission: 'LIST_ALL_PERMISSION',
  },
  {
    name: 'jobs',
    path: '/jobs',
    permission: 'LIST_ALL_JOBS',
  },
  {
    name: 'settings',
    path: '/settings',
    permission: 'LIST_SETTINGS',
  },

  {
    name: 'Shipment',
    subItems: [
      {
        name: 'New Shipment',
        path: '/new-shipment',
        permission: 'CAN_CREATE_SHIPPING_ORDER',
      },
      {
        name: 'Shipments',
        path: '/shipments',
        permission: 'CAN_LIST_SHIPPING_ORDERS',
      },
    ],
    permission: 'CAN_LIST_SHIPPING_ORDERS',
  },
];

export default routes;
