import React, { useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import { Button, Modal, Skeleton, Space, Spin } from 'antd';
import { colors, formatTime, notify } from 'utilities';
import TripDepartureBackground from '../../images/departure-icon.svg';
import TripArrivalBackground from '../../images/arrival-icon.svg';
import TripDateBackgound from '../../images/date-icon.svg';
import { ValidateUser } from '../../utilities/ValidateUser';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode.react';
import domtoimage from 'dom-to-image';
import {
  GET_PRINT_TICKET_FEES,
  GET_TICKET_PRINT,
  PRINT_TICKET,
} from 'services';
import { TicketInformationToPrint } from './ticketInformationToPrint';
// import i18next from 'i18next';

const TicketPrintContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  display: block;
`;
const TicketContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  margin-bottom: 40px;
  page-break-before: always;
  @media only screen and (max-width: 980px) {
    flex-direction: column;
  }
`;
const TripInfo = styled.div`
  background: #002d59;
  color: #fff;
  border-radius: 10px;
  padding: 34px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 33%;
  flex-grow: 1;

  p {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
  span {
    margin-inline-start: 30px;
  }
`;
const TripDetails = styled.div`
  flex-basis: 33%;
  padding: 34px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 10px;
  flex-grow: 1;
`;
const TripParameter = styled.p`
  margin: 0;
  display: flex;
  justify-content: space-between;
`;
const TripTotal = styled.p`
  margin: 0;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: space-between;

  span {
    font-weight: 700;
  }

  @media screen and (max-width: 1025px) {
    margin-top: 15px;
  }
`;
const TripActionsList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-inline-start: 10px;
  list-style: none;
  justify-content: space-between;

  a {
    font-size: 14px;
    font-weight: 500;
  }

  @media print {
    display: none;
  }
`;
const NumberWrapper = styled.p`
  margin-top: 20px;
  color: ${colors.primaryColor};
`;
const TripShare = styled.div`
  background: #fff;
  padding: 34px 20px;
  display: flex;
  flex-basis: 33%;
  justify-content: space-between;
  border-inline-start: 1px dashed #002d59;
  position: relative;
  border-radius: 10px;
  flex-direction: column;
  flex-grow: 1;

  @media only screen and (max-width: 980px) {
    border-inline-start: none;
    border-top: 1px dashed #002d59;
  }

  &::before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    top: -15px;
    ${(props) => props.start}: -15px;
    border-radius: 50%;
    background: #f6f7f9;
    box-shadow: 0 10px #fff;
  }

  &::after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -15px;
    ${(props) => props.start}: -15px;
    border-radius: 50%;
    background: #f6f7f9;
    box-shadow: 0 -10px #fff;
  }

  div:first-of-type {
    display: flex;
    justify-content: space-between;
  }
`;
const TripDepature = styled.p`
  padding-inline-start: 30px;
  background: url(${TripDepartureBackground})
    ${(props) => props.backgroundPosition} 50% no-repeat;
`;
const TripArrival = styled.p`
  padding-inline-start: 30px;
  background: url(${TripArrivalBackground})
    ${(props) => props.backgroundPosition} 50% no-repeat;
  @media screen and (max-width: 1025px) {
    margin-top: 15px !important;
  }
`;
const TicketStatus = styled.span`
  display: block;
  color: ${(props) => props.color};
  margin-bottom: 0;
  font-size: 14px;

  ::before {
    content: '';
    display: inline-block;
    border: 1px solid ${(props) => props.color};
    width: 8px;
    height: 8px;
    border-radius: 50px;
    margin-inline-end: 5px;
  }
`;
const TripDate = styled.p`
  padding-inline-start: 30px;
  background: url(${TripDateBackgound}) ${(props) => props.backgroundPosition}
    50% no-repeat;
  @media screen and (max-width: 1025px) {
    margin-top: 15px !important;
  }
`;

const ViewTickets = () => {
  ValidateUser();
  const componentRef = useRef();
  const { ticketIds } = useParams();
  const ids = ticketIds.split('&');

  const [printModalVisible, setPrintModalVisible] = useState(false);

  const handleCancel = () => {
    setPrintModalVisible(false);
  };

  const { data, loading, refetch, error } = useQuery(GET_TICKET_PRINT, {
    variables: {
      id: ids,
    },
  });
  const [getPrintFees, { data: printFees }] = useLazyQuery(
    GET_PRINT_TICKET_FEES,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [printTicket] = useMutation(PRINT_TICKET);
  return (
    <>
      {/* {data?.getTicketsPrint?.length > 0 && (
        <>
          <Button
            type="primary"
            shape="round"
            style={{ float: 'right' }}
            size="large"
            onClick={() => {
              getPrintFees({
                variables: {
                  id: ids,
                },
              });
              setPrintModalVisible(true);
            }}
          >
            Print
          </Button>
        </>
      )} */}

      <TicketPrintContainer>
        {data?.getTicketsPrint?.map((ticket, i) => {
          const saveTicket = () => {
            const el = document.getElementById(ticket.id);
            document.querySelector('.action-list').style.visibility = 'hidden';
            domtoimage.toBlob(el).then(function (blob) {
              window.saveAs(blob, `Ticket_${ticket.id}.png`);
              document.querySelector('.action-list').style.visibility = 'unset';
            });
          };
          const color =
            ticket.status === 'Cancelled'
              ? colors.danger
              : ticket.status === 'Paid'
              ? colors.success
              : colors.primaryColor;
          return (
            <TicketContainer id={ticket.id} key={i}>
              <TripInfo>
                <TripDepature backgroundPosition="0%">
                  {ticket.from_location.city.name_en} -{' '}
                  {ticket.from_location.name_en}{' '}
                  <span>{formatTime(ticket.from_time)}</span>
                </TripDepature>
                <TripArrival backgroundPosition="0%">
                  {ticket.to_location.city.name_en} -{' '}
                  {ticket.to_location.name_en}{' '}
                  <span>{formatTime(ticket.to_time)}</span>
                </TripArrival>
                <TripDate backgroundPosition="0%">
                  Departure Date <span>{ticket.from_date}</span>
                </TripDate>
              </TripInfo>
              <TripDetails>
                <TripParameter>
                  Trip Number <span>{ticket.trip.ref_code}</span>
                </TripParameter>
                <TripParameter>
                  Seat Number {ticket.seat_number}{' '}
                  <span>{ticket.seat_type.name_en}</span>
                </TripParameter>
                <TripTotal>
                  <span>
                    Price{' '}
                    <TicketStatus color={color} style={{ marginTop: '10px' }}>
                      {ticket.status}
                    </TicketStatus>
                  </span>
                  <span>{ticket.price}</span>
                </TripTotal>
              </TripDetails>
              <TripShare start="left">
                <div>
                  <div>
                    <QRCode value={ticket.qr_code_name} />
                  </div>
                  <TripActionsList className="action-list">
                    <li>
                      {ticket.status !== 'Modified' && (
                        <Button
                          style={{
                            float: 'right',
                            backgroundColor: 'white',
                            color: '#1E93FF',
                            border: ' 0px',
                          }}
                          size="large"
                          onClick={() => {
                            getPrintFees({
                              variables: {
                                id: ids,
                              },
                            });
                            setPrintModalVisible(true);
                          }}
                        >
                          Print Ticket
                        </Button>
                      )}
                    </li>
                    <li>
                      <Button
                        type="link"
                        onClick={() => {
                          saveTicket();
                        }}
                      >
                        Save Ticket
                      </Button>
                    </li>
                  </TripActionsList>
                </div>
                <NumberWrapper>
                  Order Number: {ticket.order.order_number}
                </NumberWrapper>
                <NumberWrapper>Ticket Code: {ticket.code}</NumberWrapper>
              </TripShare>
            </TicketContainer>
          );
        })}
      </TicketPrintContainer>
      <Spin spinning={loading}>
        <div style={{ display: 'none' }}>
          <TicketInformationToPrint data={data} ref={componentRef} />
        </div>
      </Spin>
      <Modal
        title={<Space size={'middle'}>Print Fees</Space>}
        visible={printModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <ReactToPrint
            trigger={() => (
              <Button key="submit" type="primary">
                Confirm
              </Button>
            )}
            onBeforePrint={() => {
              setPrintModalVisible(false);
            }}
            onAfterPrint={() => {
              ids.map((ticketId) => {
                printTicket({
                  variables: {
                    id: ticketId,
                  },
                });
              });
            }}
            content={() => componentRef.current}
          />,
        ]}
      >
        <Skeleton
          active
          round
          title={false}
          paragraph={{ rows: 2, width: ['100%', '75%'] }}
          loading={false}
        >
          {printFees && printFees.checkPrintTicketFees.printFees > 0 && (
            <p>
              Please note that printing the ticket will cost{' '}
              {printFees.checkPrintTicketFees.printFees} L.E., confirm to
              continue...
            </p>
          )}
          {printFees && printFees.checkPrintTicketFees.printFees == 0 && (
            <p>
              Please note that printing this ticket will cost{' '}
              {printFees.checkPrintTicketFees.printFees} L.E., and Re-Printing
              the ticket will cost additional fees, confirm to continue...
            </p>
          )}
        </Skeleton>
      </Modal>
    </>
  );
};

export default ViewTickets;
