import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Input, Button, Tabs, Spin } from 'antd';
import { Query, Mutation } from '@apollo/react-components';
import { SEARCH_CUSTOMERS, CreateCustomer } from 'services';
import styled from '@emotion/styled/macro';
import { Can } from 'components';
import { notify } from 'utilities';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';

const { TabPane } = Tabs;
const { Option } = Select;

const Container = styled.div`
  border-bottom: 1px solid #9b9b9b;

  .ant-form,
  .ant-form-inline {
    margin: 20px 0;
  }

  .ant-form label {
    font-size: 16px;
  }
`;

class Customers extends Component {
  state = {
    newCustomer: false,
    canSearchCustomers: false,
    customerPhoneNumber: '',
  };
  handleNewCustomer = (key) =>
    this.setState({
      newCustomer: key == '2' ? true : false,
    });
  handleCreateCustomer = (e, createCustomer) => {
    const { t } = this.props;
    const {
      form: { validateFields },
      selectCustomer,
    } = this.props;
    e.preventDefault();
    validateFields(['phone', 'name'], (err, values) => {
      if (!err) {
        const { name, phone } = values;
        createCustomer({
          variables: {
            name,
            phone,
          },
        })
          .then((response) => {
            const {
              data: { adminCreateCustomer: createdCustomer },
            } = response;
            selectCustomer(createdCustomer);
          })
          .catch((err) => {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];

            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          });
      }
    });
  };

  fetchCustomers = (customerPhoneNumber) => {
    // Kind of debouncing
    setTimeout(() => {
      this.setState({
        customerPhoneNumber,
        canSearchCustomers: customerPhoneNumber.length > 6,
      });
    }, 1000);
  };

  handleClearing = (value) => {
    const { setSelectedCustomer } = this.props;

    if (!value) setSelectedCustomer(null);
  };

  render() {
    const { t } = this.props;
    const { newCustomer, canSearchCustomers, customerPhoneNumber } = this.state;
    const {
      selectCustomer,
      searchOnly,
      form: { getFieldDecorator },
    } = this.props;

    return (
      <>
        {searchOnly ? (
          <Container>
            <Query
              query={SEARCH_CUSTOMERS}
              variables={{ customerPhoneNumber }}
              skip={!canSearchCustomers}
            >
              {({ loading, data }) => (
                <Form layout="inline">
                  {' '}
                  <Form.Item label={t('Search Customers')}>
                    {getFieldDecorator(
                      'customer',
                      {}
                    )(
                      <Select
                        showSearch
                        onSearch={this.fetchCustomers}
                        onSelect={selectCustomer}
                        notFoundContent={
                          loading ? (
                            <Spin size="small" />
                          ) : !canSearchCustomers ? (
                            t('Please, enter at least 7 digits!')
                          ) : (
                            t('Customer Not Found!')
                          )
                        }
                        style={{ width: 250 }}
                        placeholder={t("Enter Phone Number")}
                        optionFilterProp="children"
                        size="large"
                        allowClear={true}
                        onChange={this.handleClearing}
                      >
                        {data &&
                          data.searchCustomers &&
                          data.searchCustomers.map((customer, idx) => (
                            <Option key={idx} value={JSON.stringify(customer)}>
                              {`${customer.name} (${customer.phone})`}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Form>
              )}
            </Query>
          </Container>
        ) : (
          <Tabs defaultActiveKey="1" onChange={this.handleNewCustomer}>
            <TabPane tab={t('Existing Customer')} key="1">
              <Query
                query={SEARCH_CUSTOMERS}
                variables={{ customerPhoneNumber }}
                skip={!canSearchCustomers}
              >
                {({ loading, error, data }) => (
                  <Form.Item label={t('Customer')}>
                    {getFieldDecorator('customer', {
                      rules: [
                        {
                          required: !newCustomer,
                          message: t('Please select the customer'),
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        onSearch={this.fetchCustomers}
                        onSelect={selectCustomer}
                        notFoundContent={
                          loading ? (
                            <Spin size="small" />
                          ) : !canSearchCustomers ? (
                            t('Please enter at least 7 digits')
                          ) : (
                            t('Customer not found!')
                          )
                        }
                        style={{ width: 200 }}
                        placeholder={t('customer phone number')}
                        optionFilterProp="children"
                      >
                        {data &&
                          data.searchCustomers &&
                          data.searchCustomers.map((customer, idx) => (
                            <Option key={idx} value={JSON.stringify(customer)}>
                              {`${customer.name} - ${customer.phone}`}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                )}
              </Query>
            </TabPane>
            <TabPane tab={t('New Customer')} key="2">
              <Can
                perform="CREATE_CUSTOMER"
                yes={
                  <Mutation mutation={CreateCustomer}>
                    {(createCustomer) => (
                      <Form
                        layout={isMobile ? 'horizontal' : 'inline'}
                        onSubmit={(e) =>
                          this.handleCreateCustomer(e, createCustomer)
                        }
                      >
                        <Form.Item label={t('Name')}>
                          {getFieldDecorator('name', {
                            rules: [
                              {
                                required: newCustomer,
                                message: t('Please enter the customer name!'),
                              },
                            ],
                          })(<Input placeholder={t('name')} />)}
                        </Form.Item>
                        <Form.Item label={t('Phone Number')}>
                          {getFieldDecorator('phone', {
                            rules: [
                              {
                                required: newCustomer,
                                message: t(
                                  'Please enter the customer phone number!'
                                ),
                              },
                            ],
                          })(<Input placeholder={t('phone number')} />)}
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            {t('Create')}
                          </Button>
                        </Form.Item>
                      </Form>
                    )}
                  </Mutation>
                }
                no={
                  <strong>
                    {t('You do not have permission to create a customer')}
                  </strong>
                }
              />
            </TabPane>
          </Tabs>
        )}
      </>
    );
  }
}

const CustomersWithForm = Form.create({})(Customers);

export default withTranslation()(CustomersWithForm);
