import gql from 'graphql-tag';

export const UPDATE_TEMPLATE_STATUS = gql`
  mutation updateTripTemplateStatus($id: ID!, $status: Boolean!) {
    updateTripTemplateStatus(id: $id, status: $status) {
      message
      status
    }
  }
`;
export default UPDATE_TEMPLATE_STATUS;
