import React, { useRef, image } from 'react';
import { colors, formatTime, ValidateUser } from '../../utilities';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ReactToPrint from 'react-to-print';
import { GET_CASHCOLLECTION_PRINT, CONFIRM_CASH_COLLECTION } from 'services';
import styled from '@emotion/styled/macro';
import { Button } from 'antd';
import { ReactComponent as Logo } from 'images/logo.svg';
import moment from 'moment';
const CashCollectionPrintContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @page {
    size: 80mm 50mm;
  }
  @media print {
    -webkit-print-color-adjust: exact;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    page-break-before: always;
  }
`;

const CashReciptContainer = styled.div`
  width: 300px;
  height 450px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  border 1px solid black;


`;
const BlackBar = styled.div`
  background: black;
  width: 100%;
  height: 2px;
`;
const CashReciptContainerDetails = styled.div`
  width: 100%;
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const LeftSide = styled.div`
display:flex;
flex-direction: column;
align items: center:
justify-content: flex-start;
`;
const RightSide = styled.div`
display:flex;
flex-direction: column;
align items: center:
justify-content: flex-end;
`;
const ReciptParameter = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 5px;
  span {
    font-weight: normal;
    line-height: 14px;
    font-size: 14px;
    padding-left: 10px;
  }
`;

const ViewCashCollection = () => {
  ValidateUser();

  const { cashCollectionIds } = useParams();
  const ids = cashCollectionIds?.split('&');
  const now = new Date();

  const { data, loading, refetch, error } = useQuery(GET_CASHCOLLECTION_PRINT, {
    variables: {
      id: ids,
    },
  });
  const [updatePrintCashCollection] = useMutation(CONFIRM_CASH_COLLECTION);

  const componentRef = useRef();

  return (
    <>
      {data?.getCashPrint?.length > 0 && (
        <ReactToPrint
          trigger={() => (
            <Button
              type="primary"
              shape="round"
              style={{ float: 'right', margin: '10px' }}
              size="large"
            >
              Print
            </Button>
          )}
          onAfterPrint={() => {
            updatePrintCashCollection({
              variables: {
                id: ids,
              },
            });
          }}
          content={() => componentRef.current}
        />
      )}

      {data?.getCashPrint?.map((bill, i) => {
        return (
          <CashCollectionPrintContainer ref={componentRef}>
            <CashReciptContainer ref={componentRef} id={bill.id} key={i}>
              <LogoContainer>
                <Logo /> <ReciptParameter>jabal bus</ReciptParameter>
                <ReciptParameter>16128</ReciptParameter>
                <ReciptParameter>jabalbus.com.eg</ReciptParameter>
              </LogoContainer>
              <BlackBar />
              <CashReciptContainerDetails>
                <LeftSide>
                  <ReciptParameter> Receipt number</ReciptParameter>
                  <ReciptParameter>Created at</ReciptParameter>
                  <ReciptParameter>Printed at</ReciptParameter>
                  <ReciptParameter>Collected amount</ReciptParameter>
                  <ReciptParameter>User id</ReciptParameter>
                  <ReciptParameter>Username</ReciptParameter>
                  <ReciptParameter>User phone</ReciptParameter>
                  <ReciptParameter>Driver id</ReciptParameter>
                  <ReciptParameter>Driver name</ReciptParameter>
                  <ReciptParameter>Driver phone</ReciptParameter>
                  <ReciptParameter>Platform Name</ReciptParameter>
                  <ReciptParameter>Station id</ReciptParameter>
                  <ReciptParameter>Shift id</ReciptParameter>
                  <ReciptParameter>Station name</ReciptParameter>
                </LeftSide>
                <RightSide>
                  <ReciptParameter>{bill.id}</ReciptParameter>
                  <ReciptParameter>
                    {moment(bill.create_at).format('MMM DD,YYYY')}
                  </ReciptParameter>
                  <ReciptParameter>
                    {bill.printed_at
                      ? moment(bill.printed_at).format('hh:mm MMM DD,YYYY')
                      : moment(now).format('MMM DD,YYYY hh:mm,')}
                  </ReciptParameter>
                  <ReciptParameter>{bill?.amount}</ReciptParameter>
                  <ReciptParameter>{bill?.user?.id}</ReciptParameter>
                  <ReciptParameter>{bill?.user?.name}</ReciptParameter>
                  <ReciptParameter>{bill?.user?.phone}</ReciptParameter>
                  <ReciptParameter>{bill?.driver?.id}</ReciptParameter>
                  <ReciptParameter>{bill?.driver?.name}</ReciptParameter>
                  <ReciptParameter>{bill?.driver?.phone}</ReciptParameter>
                  <ReciptParameter>
                    {bill?.shift?.shift_log?.shift.name}
                  </ReciptParameter>
                  <ReciptParameter>{bill?.station_id}</ReciptParameter>
                  <ReciptParameter>{bill?.shift_id}</ReciptParameter>
                  <ReciptParameter>{bill?.station?.name_en}</ReciptParameter>
                </RightSide>
              </CashReciptContainerDetails>
              <BlackBar />
            </CashReciptContainer>
          </CashCollectionPrintContainer>
        );
      })}
    </>
  );
};

export default ViewCashCollection;
