const paymentMethods = [
  'Cash',
  'StoreCash',
  'StoreCard',
  'PartnerCredit',
  'DriverCash',
  'AutoConfirmed',
  'Card',
  'Fawry',
  'Aman',
  'Wallet',
];

export { paymentMethods };
