/**Login component that contain a form to
 * enter phone number and password to be logged into the system */
import React, { useContext } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Divider, message } from 'antd';
import styled from '@emotion/styled/macro';
import { notify } from 'utilities';
import { useHistory, Redirect, Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import userLogin from 'services/authentication/login';
import { AuthContext } from 'authContext';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const { Item } = Form;

const LoginPageContainer = styled.div`
  padding: 10%;

  .loginBtn {
    margin-right: 32px;
  }
`;
const LableContainer = styled.div`
display :flex !important;
justify-content: space-between;
`
const Login = ({ form: { validateFields, getFieldDecorator } }) => {
  const [login, { loading: loggingIn }] = useMutation(userLogin);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.language=='ar'?'rtl':'ltr';  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = i18n.language=='ar'?'rtl':'ltr';  }
  useEffect(() => {
    if (i18n.language !== 'ar' && i18n.language !== 'en') {
      changeLanguage('en');
    }
  }, []);

  const contextValue = useContext(AuthContext);
  const setSession = contextValue && contextValue.setSession;

  if (localStorage.getItem('token')) return <Redirect to="/"></Redirect>;

  const handleSubmit = (e) => {
    
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        login({
          variables: values,
        })
          .then((res) => {
            const {
              data: { userLogin },
            } = res;

            if (userLogin.code !== '200') {
              return notify('error', userLogin.message);
            }

            notify('success', userLogin.message);
            setSession(userLogin);
            history.push('/');
          })
          .catch((err) => {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];

            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          });
      }
    });
  };

  return (
    <LoginPageContainer>
      <LableContainer>
        <h1>{t('Blue Bus Dashboard')}</h1>
        {i18n.language == 'en' ? (
          <button
            style={{
              border: 'none',
              cursor: 'pointer',
              background: 'inherit',
            }}
            onClick={() => {
              changeLanguage('ar');
            }}
          >
            <p style={{ fontSize: '20px', marginBottom: '0' }}>
              تغيير الي اللغة العربية
            </p>
            <img
              style={{
                width: '33px',
                height: '33px',
              }}
              src={require('../../../src/images/download.png')}
              alt="Ar"
            ></img>
          </button>
        ) : (
          <button
            style={{
              border: 'none',
              cursor: 'pointer',
              background: 'inherit',
            }}
            onClick={() => {
              changeLanguage('en');
            }}
          >
            <p style={{ fontSize: '20px', marginBottom: '0' }}>Change To En</p>
            <img
              style={{ width: '33px', height: '33px' }}
              src={require('../../../src/images/english.e7a8cee7.png')}
              alt="En"
            ></img>
          </button>
        )}
      </LableContainer>
      <Divider />
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col span={8}>
            <Item>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: t('Please enter your phone '),
                    len: 11,
                    // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
                  },
                ],
              })(<Input size="large" placeholder={t('phone')}></Input>)}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Item>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: t('Please enter your password'),
                  },
                ],
              })(
                <Input
                  size="large"
                  type="password"
                  placeholder={t('password')}
                ></Input>
              )}
            </Item>
          </Col>
        </Row>
        <Item>
          <Button
            className="loginBtn"
            loading={loggingIn}
            type="primary"
            htmlType="submit"
          >
            {t('Log in')}
          </Button>
          <Link to="/forgot-password" style={{ marginRight: '40px' }}>
            {t('Forgot your password?')}
          </Link>
        </Item>
      </Form>
    </LoginPageContainer>
  );
};

const LoginForm = Form.create({})(Login);

export default LoginForm;
