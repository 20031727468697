/**Filter component to filter orders by phone number, order number or order status */
import React, { useState } from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, Select } from 'antd';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const Filter = ({
  form: { getFieldDecorator, validateFields, setFieldsValue, resetFields },
  refetch,
  setFilteredValues,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const { orderNumber, customerPhone, status } = values;
        setFilteredValues({
          orderNumber: orderNumber || '',
          customerPhone: customerPhone || '',
          status: status || '',
        });
        if (customerPhone === '' && orderNumber === '') {
          refetch({ orderNumber: undefined, customerPhone: undefined });
        } else if (orderNumber === '')
          refetch({ orderNumber: undefined, customerPhone });
        else if (customerPhone === '')
          refetch({ customerPhone: undefined, orderNumber });
        else refetch({ orderNumber, customerPhone });
      }
    });
  };

  const handleChange = (value) => {
    if (value === 'All Orders') refetch({ status: undefined });
    else refetch({ status: value });
  };

  const handleReset = () => {
    resetFields();
    setFilteredValues({
      orderNumber: '',
      customerPhone: '',
      status: '',
    });
    refetch({
      orderNumber: undefined,
      customerPhone: undefined,
      status: undefined,
    });
  };

  const [t, i18n] = useTranslation();
  const statusOptions = [
    'All Orders',
    'Paid',
    'Pending',
    'Cancelled',
    'Expired',
  ];
  return (
    <Form layout={isMobile ? 'horizontal' : 'inline'} onSubmit={handleSubmit}>
      <Form.Item label={t('Order Number')}>
        {getFieldDecorator('orderNumber')(
          <Input size="large" placeholder={t('Enter Order Number')}></Input>
        )}
      </Form.Item>
      <Form.Item label={t('Phone Number')}>
        {getFieldDecorator('customerPhone', {
          rules: [
            {
              len: 11,
              message: t('Invalid Phone Number'),
              // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
            },
          ],
        })(
          <Input size="large" placeholder={t('Enter Customer Phone')}></Input>
        )}
      </Form.Item>
      <Form.Item label={t('Order Status')}>
        {getFieldDecorator('status', {
          initialValue: t('All Orders'),
        })(
          <Select size="large" style={{ width: 200 }} onChange={handleChange}>
            {statusOptions.map((option, idx) => (
              <Option key={idx} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" htmlType="submit">
          {t('Filter Records')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" onClick={handleReset}>
          {t('Reset Fields')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create({})(Filter);
