/**Component that displays the seat map of the selected bus salon */
import React from 'react';

const SalonLayoutInfo = ({ seats, allSeatTypes }) => (
  <>
    {seats.map((row, idx) => (
      <div key={idx}>
        {row.map((seat) => {
          const { key, numbering, seatType, type, cols } = seat;
          //   const { name_en: seatTypeName } = allSeatTypes.find(
          //     seat => seat.id == seatType
          //   );
          return (
            <button
              type="button"
              //   title={seatTypeName}
              key={key}
              style={(() => {
                const buttonStyling = {
                  width: '50px',
                  height: '20px',
                  verticalAlign: 'top',
                };
                if (cols.length === 2) {
                  buttonStyling.width = '100px';
                }
                if (type === 'SEAT')
                  return { ...buttonStyling, backgroundColor: 'grey' };
                if (type === 'WC')
                  return { ...buttonStyling, backgroundColor: 'red' };
                if (type === 'BUFFET')
                  return { ...buttonStyling, backgroundColor: 'yellow' };
                return {
                  ...buttonStyling,
                  backgroundColor: 'grey',
                  opacity: 0.5,
                };
              })()}
              onClick={() => {}}
            >
              {numbering}
            </button>
          );
        })}
      </div>
    ))}
  </>
);

export default SalonLayoutInfo;
