/**Component for creating new promocode in the system */
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Select,
  Input,
  InputNumber,
  Button,
  Typography,
  Row,
  Col,
  Spin,
  DatePicker,
  Checkbox,
  Radio,
  Switch,
} from 'antd';
import { notify } from 'utilities';
import gql from 'graphql-tag';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  GET_ACTIVE_CITIES,
  createPromoCode as createPromoCodeMutation,
  allPromoCodes,
  UPDATE_PROMO_CODE,
} from 'services';
import { paymentMethods } from '../../config/paymentMethods';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Option } = Select;
const FormItem = Form.Item;
const { Item } = Form;
const AllSeatTypes = gql`
  query {
    allSeatTypes {
      id
      name_en
      name_ar
    }
  }
`;

const PromoCodeForm = ({
  form: { validateFields, getFieldDecorator, getFieldsValue, setFieldsValue },
  location: { state: { promocode } = {} },
}) => {
  const { data: seatTypesData } = useQuery(AllSeatTypes, {});

  const { data, loading } = useQuery(GET_ACTIVE_CITIES, {});

  const [
    createPromoCode,
    { loading: creatingPromoCode },
  ] = useMutation(createPromoCodeMutation, { awaitRefetchQueries: true });
  const [allPayments, setAllPayments] = useState(true);
  const [offline, setOffline] = useState(1);
  const [
    UpdatePromoCode,
    { loading: updatingPromoCode },
  ] = useMutation(UPDATE_PROMO_CODE, { awaitRefetchQueries: true });
  const seatTypes = seatTypesData && seatTypesData.allSeatTypes;
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const nameLang = i18n.language;
  const dateFormat = 'YYYY/MM/DD';
  useEffect(() => {
    if (promocode) {
      setAllPayments(
        promocode?.payment_methods?.length == paymentMethods?.length
      );
      setOffline(promocode.offline);
      let default_seat_types = [];
      let default_from_cities = [];
      let default_to_cities = [];
      promocode.seat_types.map((type) => {
        default_seat_types.push(type.id);
      });
      promocode.cities.map((city) => {
        if (city?.pivot?.type == 'From')
          default_from_cities.push(city.pivot.city_id);
        if (city?.pivot?.type == 'To')
          default_to_cities.push(city.pivot.city_id);
      });
      setFieldsValue({
        code: promocode.code,
        name: promocode.name,
        value_type: promocode.value_type,
        seat_type_ids: default_seat_types,
        payment_methods: promocode.payment_methods,
        value: promocode.value,
        max_tickets: promocode.max_tickets,
        min_tickets: promocode.min_tickets,
        max_quota: promocode.max_quota,
        trip_date: [
          moment(promocode.trip_start_date, dateFormat),
          moment(promocode.trip_end_date, dateFormat),
        ],
        start_date: [
          moment(promocode.start_date, dateFormat),
          moment(promocode.end_date, dateFormat),
        ],
        departureCities: default_from_cities,
        destinationCities: default_to_cities,
      });
    }
  }, [promocode, history, setFieldsValue]);
  /**Submit the form for creating new promocode */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (e) e.preventDefault();

    validateFields((err, values) => {
      const {
        code,
        name,
        seat_type_ids,
        value,
        value_type,
        max_quota,
        departureCities,
        destinationCities,
        rounded,
        first_use,
        start_date,
        trip_date,
        max_tickets,
        min_tickets,
      } = values;

      let { payment_methods } = values;
      if (allPayments) {
        payment_methods = paymentMethods;
      }
      if (!err) {
        if (max_tickets < min_tickets) {
          notify(t('error'), t('min tickets must be greater than max tickets'));
          return;
        }
        if (max_tickets > max_quota) {
          notify(t('error'), t('max tickets must be less than max quota'));
          return;
        }
        values = {
          code,
          name,
          seat_type_ids,
          value,
          value_type,
          max_quota,
          max_tickets,
          min_tickets,
          rounded: rounded ? 1 : 0,
          first_use: first_use ? 1 : 0,
          offline: offline ? offline : 2,
          start_date: start_date && start_date[0].format('YYYY-MM-DD'),
          end_date: start_date && start_date[1].format('YYYY-MM-DD'),
          trip_start_date: trip_date && trip_date[0].format('YYYY-MM-DD'),
          trip_end_date: trip_date && trip_date[1].format('YYYY-MM-DD'),
          cities: {
            from: departureCities,
            to: destinationCities,
          },
          payment_methods,
        };
        if (promocode) update(values);
        else Create(values);
      }
    });
  };

  const Create = (values) => {
    createPromoCode({
      variables: values,
      refetchQueries: () => [
        {
          query: allPromoCodes,
          variables: {
            page: 1,
            first: 5,
          },
        },
      ],
    })
      .then((res) => {
        if (res) {
          notify(t('success'), t('Promo code created successfully'));
          history.push('/promo-codes');
        } else {
          notify(t('error'), t('Error creating promo code'));
        }
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
  };
  const update = (values) => {
    values = {
      ...values,
      id: promocode.key,
    };
    UpdatePromoCode({
      variables: values,
      refetchQueries: () => [
        {
          query: allPromoCodes,
          variables: {
            page: 1,
            first: 5,
          },
        },
      ],
    })
      .then((res) => {
        if (res) {
          notify(t('success'), t('Promo code Update successfully'));
          history.push('/promo-codes');
        } else {
          notify(t('error'), t('Error Updating promo code'));
        }
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
  };

  const {
    value_type: valueType,
    min_tickets: minTickets,
    max_tickets: maxTickets,
  } = getFieldsValue();

  return (
    <>
      <Title level={2}>
        {promocode ? t('Update Promo Code') : t('New Promo Code')}
      </Title>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          style={{ width: '50%', marginLeft: '30px' }}
          onSubmit={handleSubmit}
        >
          <FormItem label={t('Code')}>
            {getFieldDecorator('code', {
              rules: [
                {
                  required: true,
                  message: t('Please enter the promo code'),
                },
              ],
            })(
              <Input
                size="large"
                placeholder={t('Please enter the promo code')}
              />
            )}
          </FormItem>
          <FormItem label={t('Name (Alias)')}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: t('Please enter an alias'),
                },
              ],
            })(
              <Input size="large" placeholder={t('Please enter the alias')} />
            )}
          </FormItem>
          <FormItem label={t('Discount Type')}>
            {getFieldDecorator('value_type', {
              rules: [
                {
                  required: true,
                  message: t('Please choose the discount type'),
                },
              ],
            })(
              <Select
                size="large"
                placeholder={t('Please choose discount type')}
              >
                <Option value={'Percentage'}>{t('Percentage')}</Option>
                <Option value={'Fixed'}>{t('Fixed Amount')}</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label={t('Eligible Seat Type(s)')}>
            {getFieldDecorator('seat_type_ids', {
              rules: [
                {
                  required: true,
                  message: t('Please choose seat type(s)'),
                },
              ],
            })(
              <Select
                mode="tags"
                size="large"
                placeholder={t('Please choose seat type(s)')}
              >
                {seatTypes &&
                  seatTypes.map((seatType) => (
                    <Option key={seatType.id}>{nameLang==="en"? seatType.name_en : seatType.name_ar}</Option>
                  ))}
              </Select>
            )}
          </FormItem>
          <Switch
            unCheckedChildren="Selected Payments"
            checkedChildren="All Payments"
            onChange={() => {
              setAllPayments(!allPayments);
            }}
            checked={allPayments}
          />

          <FormItem label={t("payment method(s)")}>
            {getFieldDecorator(
              'payment_methods',
              {}
            )(
              <Select
                mode="tags"
                size="large"
                placeholder={t("Please payment method(s)")}
                disabled={allPayments}
              >
                {seatTypes &&
                  paymentMethods.map((method, index) => (
                    <Option key={method}>{method}</Option>
                  ))}
              </Select>
            )}
          </FormItem>

          <FormItem label={t("Departure Cities:")}>
            {getFieldDecorator('departureCities')(
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                tokenSeparators={[',']}
                size="large"
                placeholder={t("Please choose departure cities")}
                filterOption={true}
                optionFilterProp="children"
              >
                {data &&
                  data.activeCities &&
                  data.activeCities.map((city) => (
                    <Option key={city.id} value={city.id}>
                      {city.name_en}
                    </Option>
                  ))}
              </Select>
            )}
          </FormItem>
          <FormItem label={t("Destination Cities:")}>
            {getFieldDecorator('destinationCities')(
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                tokenSeparators={[',']}
                size="large"
                placeholder={t("Please choose destination cities")}
                filterOption={true}
                optionFilterProp="children"
              >
                {data &&
                  data.activeCities &&
                  data.activeCities.map((city) => (
                    <Option key={city.id} value={city.id}>
                      {city.name_en}
                    </Option>
                  ))}
              </Select>
            )}
          </FormItem>
          <FormItem label={t("Rounded")}>
            {getFieldDecorator('rounded', {
              initialValue: promocode?.rounded || '',
              valuePropName: 'checked',
            })(<Checkbox size="large" />)}
          </FormItem>
          <FormItem label={t("first use")}>
            {getFieldDecorator('first_use', {
              initialValue: promocode?.first_use || '',
              valuePropName: 'checked',
            })(<Checkbox size="large" />)}
          </FormItem>
          <FormItem label={t("Type")}>
            {getFieldDecorator('offline', {
              valuePropName: 'checked',
            })(
              <Radio.Group
                onChange={(e) => {
                  setOffline(e.target.value);
                }}
                value={offline}
              >
                <Radio value={3}>{t("General")}</Radio>
                <Radio value={1}>{t("Offline")}</Radio>
                <Radio value={2}>{t("Online")}</Radio>
              </Radio.Group>
            )}
          </FormItem>
          <FormItem label={t("Amount")}>
            {getFieldDecorator('value', {
              rules: [
                {
                  required: true,
                  message: t('Please enter the amount'),
                },
                {
                  validator: (_, value, callback) => {
                    if (
                      value < 0 ||
                      (value > 100 && valueType === 'Percentage')
                    ) {
                      callback('Invalid Number!');
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<InputNumber min="0" size="large" placeholder="0" />)}
          </FormItem>
          <FormItem label={t("Max #tickets")}>
            {getFieldDecorator('max_tickets', {
              rules: [
                {
                  required: true,
                  message: t('Please enter valid value '),
                },
              ],
            })(<InputNumber min="1" size="large" placeholder="0" />)}
          </FormItem>
          <FormItem label={t("Min #tickets")}>
            {getFieldDecorator('min_tickets', {
              rules: [
                {
                  required: true,
                  message: t('Please enter valid value '),
                },
                {
                  validator: (_, value, callback) => {
                    if (maxTickets < minTickets) {
                      callback('Invalid Number!');
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<InputNumber min="1" size="large" placeholder="0" />)}
          </FormItem>
          <FormItem label={t("Max Quota")}>
            {getFieldDecorator('max_quota', {
              rules: [
                {
                  required: true,
                  message: t('Please enter the max quota'),
                },
                {
                  validator: (_, value, callback) => {
                    if (value < 1) {
                      callback('Invalid Number!');
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<InputNumber min="1" size="large" placeholder="0" />)}
          </FormItem>
          <FormItem label={t("Promo Date Range")}>
            {getFieldDecorator('start_date', {
              rules: [
                {
                  required: true,
                  message: t('Please, enter the promo range dates!'),
                },
              ],
            })(
              <RangePicker
                placeholder={[t("Start date"), t("End date")]}
                size="large"
                disabledDate={(current) => {
                  return current && current < moment().startOf('day');
                }}
              />
            )}
          </FormItem>
          <FormItem label={t("Trip Date Range")}>
            {getFieldDecorator('trip_date', {
              rules: [
                {
                  required: true,
                  message: t('Please, enter the promo trip dates !'),
                },
              ],
            })(
              <RangePicker
                placeholder={[t("Start date"), t("End date")]}
                size="large"
                disabledDate={(current) => {
                  return current && current < moment().startOf('day');
                }}
              />
            )}
          </FormItem>
          <Spin spinning={creatingPromoCode || updatingPromoCode}>
            <Button type="primary" htmlType="submit">
              {promocode ? t('Update') : t('Create')}
            </Button>
          </Spin>
          <Button
            onClick={() => {
              history.push('/promo-codes');
            }}
            type="default"
          >
            {t("Cancel")}
          </Button>
        </Form>
      </Spin>
    </>
  );
};

const PromoCode = Form.create()(PromoCodeForm);
export default PromoCode;
