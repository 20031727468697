/** Displays the available types of bus seats with color code
 * Contains form for selecting seat type, class and bus salon name */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Input, Button, Spin, notification } from 'antd';
import { CreateBusSalon, allSeatTypes, busSalonList } from 'services';
import { Query, Mutation } from '@apollo/react-components';
import BusSalon from './bus-salon';
import GridBuilder from './grid-builder';
import styled from '@emotion/styled/macro';
import { notify } from 'utilities';
import { ListHeader, PrimaryTitle } from 'components';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';


const Option = Select.Option;

const BusBuilderContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const FormWrapper = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  button {
    margin-bottom: 32px;
    width: fit-content;
  }

  .ant-input:placeholder-shown {
    width: 32.5%;
  }
`;

class BusBuilder extends Component {
  state = {
    selectedSeatType: [],
    seats: [],
    selectedSeats: [],
  };

  setSelectedSeats = (seats) => this.setState({ seats });

  selectSeat = (key) => {
    const { selectedSeats } = this.state;
    const keyIndex = selectedSeats.indexOf(key);
    if (keyIndex === -1) selectedSeats.push(key);
    else selectedSeats.splice(keyIndex, 1);
    this.setState({ selectedSeats });
  };

  findSeat = (key) => {
    const { seats } = this.state;
    let foundSeat;
    seats.forEach((row) => {
      row.forEach((seat) => {
        if (seat.key === key) foundSeat = seat;
      });
    });
    return foundSeat;
  };

  handleSplit = () => {
    const { selectedSeats, seats } = this.state;
    if (selectedSeats.length !== 1) {
      alert('Can only split one seat at a time');
      return;
    }

    const mergedSeat = this.findSeat(selectedSeats[0]);
    if (mergedSeat.seatType !== 2) {
      alert('You only can split seat of type 2');
      return;
    }

    seats.forEach((row, i) => {
      row.forEach((seat, j) => {
        if (seat === mergedSeat) {
          const newSeat = { ...seat };
          newSeat.cols = [seat.cols[0]];
          newSeat.seatType = 1;
          newSeat.key = seat.key + 1;
          seat.cols = [seat.cols[1]];
          seat.seatType = 1;
          row.splice(j + 1, 0, newSeat);
          this.setState({ seats, selectedSeats: [] });
          this.reNumberSeats();
        }
      });
    });
  };

  handleMerge = () => {
    const { selectedSeats, seats } = this.state;
    if (selectedSeats.length !== 2) {
      alert('Selected seats should be equal 2');
      return;
    }

    selectedSeats.sort((a, b) => a - b);
    const firstSeatKey = selectedSeats[0];
    const secondSeatKey = selectedSeats[1];

    if (secondSeatKey - firstSeatKey !== 1) {
      alert('Selected seats should be consecutive');
      return;
    }

    const firstSeat = this.findSeat(firstSeatKey);
    const secondSeat = this.findSeat(secondSeatKey);

    if (firstSeat.type !== 'SEAT' || secondSeat.type !== 'SEAT') {
      alert('Selected seats types are not allowed to be merged');
      return;
    }

    if (JSON.stringify(firstSeat.rows) !== JSON.stringify(secondSeat.rows)) {
      alert('Selected seats are not in the same row');
      return;
    }

    if (firstSeat.cols.length !== 1 || secondSeat.cols.length !== 1) {
      alert('Cannot merge seats of type 2');
      return;
    }

    seats.forEach((row, i) => {
      row.forEach((seat, j) => {
        if (seat === firstSeat) {
          seat.cols = seat.cols.concat(secondSeat.cols);
        } else if (seat === secondSeat) {
          row.splice(j, 1);
        }
      });
    });
    this.setState({ seats, selectedSeats: [] });
    this.reNumberSeats();
  };

  reNumberSeats = () => {
    const { seats } = this.state;
    let counter = 1;
    seats.forEach((row, i) => {
      row.forEach((seat, j) => {
        if (seat.type !== 'SEAT') {
          seat.numbering = '';
        } else {
          seat.numbering = counter;
          counter += 1;
        }
      });
    });
    this.setState({ seats });
  };

  handleChangeSeatType = (value) => {
    if (!value) return;
    const type = value;
    const { selectedSeats, seats } = this.state;
    seats.forEach((row) => {
      row.forEach((seat) => {
        if (selectedSeats.includes(seat.key)) {
          seat.type = type;
        }
      });
    });
    this.setState({
      seats,
      selectedSeats: [],
    });
    this.reNumberSeats();
    setTimeout(() => {
      this.props.form.resetFields('seatType');
    }, 500);
  };

  handleChangeSeatClass = (value) => {
    if (!value) return;
    const type = value;
    const { selectedSeats, seats } = this.state;
    seats.forEach((row) => {
      const seatsPerRow = row
        .filter((seat) => seat.type === 'SEAT')
        .map((seat) => seat.key);

      if (seatsPerRow.some((s) => selectedSeats.includes(s))) {
        row.forEach((seat) => {
          if (selectedSeats.includes(seat.key) && seat.type === 'SEAT') {
            seat.seatType = type;
          }
        });
      } else if (seatsPerRow.some((s) => selectedSeats.includes(s))) {
        notify(
          'error',
          'Please, select all sets in a row to change their class!'
        );
      }
    });
    this.setState({
      selectedSeats: [],
    });
    this.props.form.resetFields(['seatClass']);
  };

  resetSelection = () => {
    this.setState({ selectedSeats: [] });
  };

  render() {

    const nameLang = i18next.language;
    const { t } = this.props;
    const {
      form: { getFieldDecorator, validateFields, resetFields },
    } = this.props;
    const seatTypes = ['SEAT', 'WC', 'NA', 'DRIVER'];

    const { seats, selectedSeats } = this.state;
    const seatsGql = seats.map((row) =>
      row.map(({ seatType, type, row, col, ...seat }) => ({
        ...seat,
        seat_type_id: type !== 'SEAT' ? undefined : seatType,
        type,
      }))
    );

    return (
      <Query query={allSeatTypes}>
        {({ data, loading, error }) => {
          if (loading) return 'Loading ... ';
          if (error) return `${error}`;
          const { allSeatTypes } = data;
          return (
            <>
              <ListHeader>
                <PrimaryTitle>{t("Grid Builder")}</PrimaryTitle>
              </ListHeader>
              <GridBuilder setSelectedSeats={this.setSelectedSeats} />
              <BusBuilderContainer>
                <BusSalon
                  seats={seats}
                  allSeatTypes={allSeatTypes}
                  selectedSeats={selectedSeats}
                  selectSeat={this.selectSeat}
                />
                {seats.length > 0 && (
                  <FormWrapper>
                    <Button type="primary" onClick={this.resetSelection}>
                      {t("Reset Selection")}
                    </Button>

                    <Mutation
                      mutation={CreateBusSalon}
                      refetchQueries={() => [
                        {
                          query: busSalonList,
                          variables: {
                            page: 1,
                            first: 5,
                          },
                        },
                      ]}
                    >
                      {(createBusSalon, { loading, error, data, called }) => {
                        if (loading) return <Spin />;
                        if (data && called && !error & !loading) {
                          // TODO: fix warning caused by the notification toastr
                          notification['success']({
                            message: t('Bus Salon Creation Success'),
                            description:
                              t('Bus salon has been created successfully'),
                          });
                          return <Redirect to={{ pathname: '/salons' }} />;
                        }
                        return (
                          <Form
                            layout="vertical"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validateFields((err, values) => {
                                if (!err) {
                                  const { name } = values;
                                  createBusSalon({
                                    variables: {
                                      seats: seatsGql,
                                      name,
                                    },
                                  }).catch((err) => {
                                    const {
                                      extensions: { validation },
                                      message,
                                    } = err['graphQLErrors'][0];

                                    if (validation) {
                                      for (let error in validation) {
                                        notify('error', validation[error][0]);
                                      }
                                    } else {
                                      notify('error', message);
                                    }
                                  });
                                }
                              });
                            }}
                          >
                            <Form.Item>
                              {getFieldDecorator(
                                'seatType',
                                {}
                              )(
                                <Select
                                  showSearch
                                  style={{ width: 200 }}
                                  optionFilterProp="children"
                                  placeholder={t("Select Seat Type")}
                                  onChange={this.handleChangeSeatType}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  allowClear={true}
                                  disabled={!selectedSeats.length}
                                >
                                  {seatTypes.map((option, idx) => (
                                    <Option key={idx} value={option}>
                                      {t(option).toLowerCase()}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            </Form.Item>
                            <Form.Item>
                              {getFieldDecorator(
                                'seatClass',
                                {}
                              )(
                                <Select
                                  style={{ width: 200 }}
                                  placeholder={t("Select Seat Class")}
                                  onChange={this.handleChangeSeatClass}
                                  allowClear={true}
                                  disabled={!selectedSeats.length}
                                >
                                  {allSeatTypes.map((option, idx) => (
                                    <Option key={idx} value={option.id}>
                                      {nameLang==="en" ? option.name_en : option.name_ar}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            </Form.Item>

                            <Form.Item>
                              {getFieldDecorator('name', {
                                rules: [
                                  {
                                    required: true,
                                    message: t('Please enter the bus salon name'),
                                  },
                                ],
                              })(<Input placeholder={t("bus salon name")} />)}
                            </Form.Item>
                            <Form.Item>
                              <Button type="primary" htmlType="submit">
                                {t("Submit Bus Salon")}
                              </Button>
                            </Form.Item>
                          </Form>
                        );
                      }}
                    </Mutation>
                  </FormWrapper>
                )}
              </BusBuilderContainer>
            </>
          );
        }}
      </Query>
    );
  }
}

export default Form.create({ name: 'bus_builder' })(withTranslation()(BusBuilder));
