/**Filter component to filter orders by phone number, order number or order status */
import React, { useState } from 'react';

import '@ant-design/compatible/assets/index.css';

import { Input, Form, Button, Select, Spin, Space, InputNumber } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { allRoles, GET_ACTIVE_LOCATIONS } from '../../services';
import { isMobile } from 'react-device-detect';
import './styledRoles.css';
import { useTranslation } from 'react-i18next';

const { Option, OptGroup } = Select;
const { Item } = Form;
const UserFilter = ({ refetch, resetPage, setFilteredValues }) => {
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();

  const { data: locationsData, loading: loadingLocations } = useQuery(
    GET_ACTIVE_LOCATIONS
  );
  const { data: all_roles, loading: rolesLoading } = useQuery(allRoles, {
    variables: {
      first: 1000,
      page: 1,
    },
  });
  const roles = all_roles?.allRoles?.data;

  const { activeLocations } = locationsData || { activeLocations: [] };

  const availableLocations = activeLocations.reduce((result, location) => {
    const cityName = location.city.name_en;
    const cityId = location.city.id;

    if (!Object.keys(result).includes(cityName)) {
      result[cityName] = [{ ...location, city_id: cityId }];
    } else {
      result[cityName].push({ ...location, city_id: cityId });
    }
    return result;
  }, {});

  const handleSubmit = (values) => {
    resetPage();
    setFilteredValues({
      id: values.id || undefined,
      phone: values.phone || undefined,
      station: values.station || undefined,
      role_id: values.role_id || undefined,
    });
    refetch({
      id: values.id || undefined,
      phone: values.phone || undefined,
      station: values.station || undefined,
      role_id: values.role_id || undefined,
    });
  };

  const handleReset = () => {
    form.resetFields();
    resetPage();
    setFilteredValues({
      id: undefined,
      phone: undefined,
      station: undefined,
      role_id: undefined,
    });
    refetch({
      id: undefined,
      phone: undefined,
      station: undefined,
      role_id: undefined,
    });
  };

  return (
    <div>
      <Form
        form={form}
        layout={isMobile ? 'horizontal' : 'inline'}
        onFinish={handleSubmit}
        style={{ padding: 15, width: '100%' }}
      >
        <Item
          rules={[{ type: 'number', min: 1 }]}
          style={{ marginBottom: 0, flex: '1 1 0' }}
          name="id"
          label={t('id')}
        >
          <InputNumber placeholder={t('id')} />
        </Item>
        <Item
          rules={[
            {
              len: 11,
              message: t('Invalid Phone Number'),
              // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
            },
          ]}
          style={{ marginBottom: 0, flex: '1 1 0' }}
          name="phone"
          label={t('phone')}
        >
          <Input placeholder={t('phone')} />
        </Item>
        <Item
          style={{ marginBottom: 0, flex: '1 1 0' }}
          name="station"
          label={t('Station')}
        >
          <Select
            placeholder={t('Select Assigned Station')}
            size="large"
            showSearch
            optionFilterProp="children"
            allowClear
          >
            {loadingLocations ? (
              <Option value={null} disabled style={{ textAlign: 'center' }}>
                <Spin tip="Loading Locations..." />
              </Option>
            ) : (
              Object.keys(availableLocations).map((cityName, cityIndex) => (
                <OptGroup key={`city_${cityIndex}`} label={cityName}>
                  {availableLocations[cityName].map(
                    (location, locationIndex) => (
                      <Option
                        key={`location_${cityIndex}_${locationIndex}`}
                        value={location.id}
                      >
                        {location.name_en}
                      </Option>
                    )
                  )}
                </OptGroup>
              ))
            )}
          </Select>
        </Item>
        <Item
          style={{ marginBottom: 0, flex: '1 1 0' }}
          name="role_id"
          label={t('Role')}
        >
          <Select
            placeholder={t('Select Assigned Role')}
            size="large"
            showSearch
            optionFilterProp="children"
            allowClear
          >
            {rolesLoading ? (
              <Option value={null} disabled style={{ textAlign: 'center' }}>
                <Spin tip="Loading Roles..." />
              </Option>
            ) : (
              roles.map((role, roleIndex) => (
                <Option key={`role_${roleIndex}`} value={role.id}>
                  {role.name}
                </Option>
              ))
            )}
          </Select>
        </Item>

        <Item>
          <Button type="primary" htmlType="submit" style={{ height: '38px' }}>
            {t('Search')}
          </Button>
        </Item>
        <Item>
          <Button
            type="primary"
            onClick={handleReset}
            style={{ height: '38px' }}
          >
            {t('reset')}
          </Button>
        </Item>
      </Form>
    </div>
  );
};

export default UserFilter;
