/**This component renders a form to enter phone number and sends
 * verification code (sms) to reset password
 */
import React, { useContext } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Divider, Spin } from 'antd';
import styled from '@emotion/styled/macro';
import { notify } from 'utilities';
import { useHistory, Redirect, Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { forgotPassword } from 'services';
import { useTranslation } from 'react-i18next';

const { Item } = Form;

const ForgotPasswordContainer = styled.div`
  padding: 10%;

  .loginBtn {
    margin-right: 32px;
  }
`;

const ForgotPassword = ({
  form: { validateFields, getFieldDecorator },
  setPhone,
}) => {
  const [sendVerificationCode, { loading: loggingIn }] = useMutation(
    forgotPassword
  );
  const [t,i18n]=useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        sendVerificationCode({
          variables: values,
        })
          .then((res) => {
            const {
              data: { userForgotPassword },
            } = res;

            if (userForgotPassword.code !== '200') {
              return notify('error', userForgotPassword.message);
            }

            notify('success', userForgotPassword.message);
            setPhone(values.phone);
          })
          .catch((err) => {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];

            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          });
      }
    });
  };

  return (
    <ForgotPasswordContainer>
      <h1>{t("Blue Bus Dashboard")}</h1>
      <Divider />
      <h2>{t("Forgot your password?")}</h2>
      <p>
        {t("Enter your phone number and we'll send you an SMS with a code to reset your password.")}
      </p>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col span={8}>
            <Item>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your phone ',
                    len: 11,
                    // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
                  },
                ],
              })(<Input size="large" placeholder="phone"></Input>)}
            </Item>
          </Col>
        </Row>

        <Item>
          <Button
            className="loginBtn"
            loading={loggingIn}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
          <Link to="/login">Back to login</Link>
        </Item>
      </Form>
    </ForgotPasswordContainer>
  );
};

const ForgotPasswordForm = Form.create({})(ForgotPassword);

export default ForgotPasswordForm;
