import gql from 'graphql-tag';

export const GET_ORDERS = gql`
  query shippingOrdersList($first: Int!, $page: Int) {
    shippingOrdersList(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        count
        total
        currentPage
      }
      data {
        id
        order_number
        next_action_label
        sender {
          id
          name
          phone
          national_id
        }
        receiver {
          id
          name
          phone
          national_id
        }
        dispatchedBy {
          id
          name
        }
        receivedBy {
          id
          name
        }
        from_location {
          id
          name_en
          name_ar
        }
        to_location {
          id
          name_en
          name_ar
        }
        trip_route_line {
          id
          trip {
            id
            ref_code
            date
            time
          }
          from_city {
            id
            name_en
            name_ar
          }
          to_city {
            id
            name_en
            name_ar
          }
        }
        status {
          value
          label
        }
        total_price
        created_at
        creatable {
          id
          phone
        }
        items {
          id
          name
          price
          initial_weight
          extra_weight
          quantity
          category {
            id
            name
          }
        }
      }
    }
  }
`;

export default GET_ORDERS;
