/**Component for creating new driver */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Divider, Spin } from 'antd';
import { CreateDriver } from 'services';
import { notify } from 'utilities';
import { ListHeader, PrimaryTitle } from 'components';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const CreateDriverForm = ({ form: { getFieldDecorator, validateFields } }) => {
  const history = useHistory();
  const [t,i18n]=useTranslation();
  const [createDriver, { loading, error }] = useMutation(CreateDriver);

  /**Submit the form for creating new driver */
  const handleCreateDriver = (e, createDriver) => {
    e.preventDefault();

    validateFields(['phone', 'name', 'ssn'], (err, values) => {
      if (!err) {
        const { name, phone, ssn } = values;
        createDriver({
          variables: {
            name,
            phone,
            ssn,
          },
        })
          .then((res) => {
            const {
              data: {
                driverCreate: { status, message },
              },
            } = res;

            if (status) {
              notify('success', message);
              history.push('/drivers');
            }
          })
          .catch((err) => {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];

            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          });
      }
    });
  };

  return (
    <>
      <ListHeader>
        <PrimaryTitle>{t("Create Driver")}</PrimaryTitle>
      </ListHeader>

      <Divider />
      <Form
        layout={isMobile ? 'horizontal' : 'inline'}
        onSubmit={(e) => handleCreateDriver(e, createDriver)}
      >
        <Form.Item label={t("Name")}>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: t('Please enter the driver name!'),
              },
            ],
          })(<Input placeholder={t("name")} />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('phone', {
            rules: [
              {
                required: true,
                message: t('Please enter a valid driver phone number'),
                len: 11,
                // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
              },
            ],
          })(<Input placeholder={t("phone number")} />)}
        </Form.Item>
        <Form.Item label={t("National ID")}>
          {getFieldDecorator('ssn', {
            rules: [
              {
                required: true,
                message: t('Please enter the driver national ID!'),
                len: 14,
              },
            ],
          })(<Input placeholder={t("Enter National ID")} />)}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {loading && <Spin></Spin>}
            {t("Create")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const CreateDriverFormWrapper = Form.create({})(CreateDriverForm);

export default CreateDriverFormWrapper;
