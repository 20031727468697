import React, { useRef, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ReactToPrint from 'react-to-print';
import { PRINT_LUGGAGE_LABEL, CHECK_PRINT_TICKET_LABEL } from 'services';
import styled from '@emotion/styled/macro';
import { Button } from 'antd';
import { ReactComponent as Logo } from 'images/EnglishLogoWhite.svg';
import moment from 'moment';
import { notify, ValidateUser } from 'utilities';

const TicketPrintContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  align-items: center;
  direction:ltr;
  @media print {
    display: block;
  }
`;
const TicketContainer = styled.div`
  width: 380px;
  height: 165px;
  padding: 15px;
  display: flex;
  align-item: center;
  align-self: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 15px;
  border: 1px solid black;
  @media print {
    page-break-before: always !important;
    page-break-after: always !important;
    margin: 0px 3px 0px 3px !important;
    border: none;
  }
  @media only screen and (max-width: 980px) {
    // flex-direction: column;
  }
`;
const TripDetails = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  align-items: flex-start;
`;
const TripParameter = styled.h4`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  span {
    font-weight: normal;
    line-height: 14px;
    font-size: 14px;
    padding-left: 10px;
  }
`;
const LogoContainer = styled.div`
  padding-bottom: 4px;
`;
const BlackBar = styled.div`
  background: black;
  width: 100%;
  height: 1px;
`;
const RightSide = styled.div`
  transform: rotate(270deg);
  flex-direction: column;
  align-item: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ViewLuggageLabels = () => {
  ValidateUser();

  const componentRef = useRef();
  const history = useHistory();

  const { ticketIds } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const luggageNumber = query.get('lugNum') || 1;
  const ids = ticketIds.split('&');

  const { data, error, refetch } = useQuery(CHECK_PRINT_TICKET_LABEL, {
    variables: {
      ticketId: ids,
      luggageNumber: luggageNumber,
    },
  });

  useEffect(() => {
    if (error) {
      if (error['graphQLErrors'][0]?.extensions) {
        const {
          extensions: { validation },
          message,
        } = error['graphQLErrors'][0];
        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        }
        notify('error', message);
      }
    }
  }, [error]);

  const [printLuggageLabel] = useMutation(PRINT_LUGGAGE_LABEL, {
    onError: (err) => {
      if (err['graphQLErrors'][0]?.extensions) {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];
        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
          return;
        }
        notify('error', message);
      }
    },
  });

  const handleTicketConstruction = () => {
    const Rows = [];
    if (
      data &&
      data.checkPrintTicketLuggage &&
      data.checkPrintTicketLuggage.tickets
    ) {
      data.checkPrintTicketLuggage.tickets.forEach((ticket, i) => {
        for (let x = 0; x < luggageNumber * 2; x++) {
          Rows.push(
            <TicketContainer id={ticket.id} key={i + x}>
              <RightSide>
                <LogoContainer>
                  <Logo />
                </LogoContainer>
                <TripParameter>0122 710 4444</TripParameter>
                <TripParameter>jabalbus.com</TripParameter>
                <BlackBar />
              </RightSide>
              <TripDetails>
                <TripParameter>Ticket Tag</TripParameter>
                <TripParameter>
                  Luggage Serial
                  <span>{ticket.code}</span>
                </TripParameter>
                <TripParameter>
                  Seat
                  <span>{ticket.seat_number}</span>
                </TripParameter>
                <TripParameter>
                  Trip
                  <span>{ticket?.trip?.ref_code}</span>
                </TripParameter>
                <TripParameter>
                  Date
                  <span>
                    {moment(ticket?.trip?.date).format('MMM DD,YYYY')}
                  </span>
                </TripParameter>
              </TripDetails>
            </TicketContainer>
          );
        }
      });
    }
    return Rows;
  };
  return (
    <>
      {data?.checkPrintTicketLuggage?.tickets?.length > 0 && (
        <ReactToPrint
          trigger={() => (
            <Button
              type="primary"
              shape="round"
              style={{ float: 'right' }}
              size="large"
            >
              Print
            </Button>
          )}
          onBeforeGetContent={async () => {
            await new Promise((resolve, reject) => {
              refetch()
                .then((res) => {
                  if (
                    res.data &&
                    res.data.checkPrintTicketLuggage &&
                    res.data.checkPrintTicketLuggage.tickets
                  ) {
                    resolve();
                  } else {
                    notify('error', 'you cannot print more than 2 luggage');
                    history.push('/tickets');
                  }
                })
                .catch((err) => {
                  if (err['graphQLErrors'][0]?.extensions) {
                    const {
                      extensions: { validation },
                      message,
                    } = err['graphQLErrors'][0];
                    reject();
                  }
                });
            });
          }}
          onAfterPrint={() => {
            printLuggageLabel({
              variables: {
                id: ids,
                luggageNumber: luggageNumber || 1,
              },
            });
            refetch();
          }}
          content={() => componentRef.current}
        />
      )}

      <TicketPrintContainer ref={componentRef}>
        {handleTicketConstruction()}
      </TicketPrintContainer>
    </>
  );
};

export default ViewLuggageLabels;
