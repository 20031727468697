import gql from 'graphql-tag';

export const EXPORT_SEGMENTS_REPORT = gql`
  mutation exportPartnerSegments {
    exportPartnerSegments {
      status
      message
      data {
        downloadable_link
      }
    }
  }
`;

export default EXPORT_SEGMENTS_REPORT;
