/**Component for assigning busses and drivers to certain routline
 * list all available routline (from , to) cities with an
 * action button to assign bus and drivers
 */
import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Popover, Button, Checkbox, Select, Spin } from 'antd';
import { Query, Mutation } from '@apollo/react-components';
import {
  // searchBusses,
  // searchDrivers,
  allBuses,
  assignBusAndDrivers,
  GET_DRIVERS,
  getTrip,
} from 'services';
import BusDriverForm from './bus-driver-assign';
import { ListHeader, PrimaryTitle } from 'components';
import { notify } from '../../utilities';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

const FormItem = Form.Item;
const Option = Select.Option;
const EditTripInstance = ({
  match,
  form: { getFieldDecorator, validateFields },
}) => {
  const [t, i18n] = useTranslation();
  const nameLang = i18n.language
  const {
    params: { id: tripId, salon_id: salonId },
  } = match;

  const [visible, handleVisibleChange] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [
    assignBusAndDriversAction,
    { loading: assigningDrivers },
  ] = useMutation(assignBusAndDrivers);
  const { data: bussesData, loading } = useQuery(allBuses);

  const [
    getTripData,
    { loading: tripLoading, error, data: tripData, refetch },
  ] = useLazyQuery(getTrip);

  const handleCheckbox = (record) => {
    if (selectedRows.length === 0) setSelectedRows([record.id]);
    else {
      if (selectedRows.includes(record.id)) {
        selectedRows.splice(selectedRows.indexOf(record.id), 1);
      } else setSelectedRows([...selectedRows, record.id]);
    }
  };
  useEffect(() => {
    if (tripId) {
      getTripData({
        variables: {
          id: tripId,
        },
      });
    }
  }, [getTripData, tripId]);

  const handleAssignBusAndDrivers = (e, assignBusAndDriversAction) => {
    e.preventDefault();

    if (selectedRows.length < 1) {
      notify('error', t('select RoutLine First !'));
      return;
    }
    // return

    validateFields(['bus', 'drivers'], (err, values) => {
      if (!err) {
        const { bus, drivers } = values;
        selectedRows.map((routLine, index) => {
          assignBusAndDriversAction({
            variables: {
              buses_drivers: [
                {
                  trip_route_line_id: routLine,
                  bus_id: bus,
                  drivers,
                },
              ],
            },
          })
            .then((response) => {
              const {
                data: {
                  assignBusAndDrivers: { message_ar, message, status },
                },
              } = response;
              const notificationType = status ? 'success' : 'error';
              notify(notificationType, nameLang==="en"? message: message_ar);
              if (index + 1 == selectedRows.length) {
                window.location.reload();
              }
            })
            .catch((err) => {
              const {
                extensions: { validation },
                message,
                message_ar,
              } = err['graphQLErrors'][0];

              if (validation) {
                for (let error in validation) {
                  notify('error', validation[error][0]);
                }
              } else {
                notify('error', nameLang==="en" ? message : t(message));
              }
            });
        });
      }
    });
  };

  // TODO: SearchBuses by salonId
  return (
    <>
      <ListHeader>
        <PrimaryTitle>{t('Assign Bus and Drivers')}</PrimaryTitle>
      </ListHeader>

      <>
        {bussesData && bussesData.allBusses && (
          <Query query={GET_DRIVERS} variables={{ first: 10000 }}>
            {({ loading: driversLoading, error, data: driversData }) => {
              if (
                driversLoading ||
                !driversData ||
                !driversData.allDrivers ||
                !driversData.allDrivers.data
              )
                return 'Loading...';
              if (error) return 'Error...';
              const columns = [
                {
                  title: 'Select',
                  render: (text, record, index) => {
                    return (
                      <Checkbox
                        style={{ marginLeft: '30px' }}
                        onChange={(e) => {
                          handleCheckbox(record);
                        }}
                      ></Checkbox>
                    );
                  },
                },
                {
                  title: 'From City',
                  dataIndex: ['from_city', nameLang==="en"? 'name_en': 'name_ar'],
                },
                {
                  title: 'To City',
                  dataIndex: ['to_city',  nameLang==="en"? 'name_en': 'name_ar'],
                },
                {
                  /**Action button for displaying assign bus and drivers form */
                  title: 'Action',
                  dataIndex: 'action',
                  key: 'action',
                  render: (text, record, index) => {
                    const routeLineId = record.id;
                    return (
                      <div>
                        <Popover
                          content={
                            <BusDriverForm
                              bussesData={bussesData.allBusses || []}
                              driversData={driversData.allDrivers.data || []}
                              routeLineId={routeLineId}
                              routeLine={record}
                              handleVisibleChange={handleVisibleChange}
                            />
                          }
                          title={t('Assign bus and drivers')}
                          trigger="click"
                          placement="left"
                          visible={visible[routeLineId] || false}
                          onVisibleChange={(shouldClose) =>
                            handleVisibleChange({
                              ...visible,
                              [routeLineId]: shouldClose,
                            })
                          }
                        >
                          <Button
                            icon={<EditOutlined />}
                            onClick={() => handleVisibleChange(true)}
                          />
                        </Popover>
                      </div>
                    );
                  },
                },
                {
                  title: 'Bus Code',
                  render: (record) => {
                    if (record.bus) return record.bus.ref_code;
                  },
                },
                {
                  title: 'Drivers',
                  render: (record) => {
                    return (
                      record.drivers &&
                      record.drivers.map((driver) => (
                        <>
                          <ul>
                            <li>{driver.name}</li>
                          </ul>
                        </>
                      ))
                    );
                  },
                },
              ];
              //Translate Function For columns
              const ti81n = columns.map((ele) => {
                ele.title = t(ele.title);
              });

              return (
                <>
                  <Spin spinning={assigningDrivers}>
                    <Form
                      style={{ width: '25%' }}
                      layout="vertical"
                      onSubmit={(e) => {
                        handleAssignBusAndDrivers(e, assignBusAndDriversAction);
                      }}
                    >
                      <FormItem label={t('Bus')}>
                        {getFieldDecorator('bus', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter the bus!',
                            },
                          ],
                        })(
                          <Select showSearch>
                            {bussesData &&
                              bussesData.allBusses &&
                              bussesData.allBusses.map((option, idx) => (
                                <Option key={idx} value={option.id}>
                                  {option.ref_code}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </FormItem>

                      <FormItem label={t('Drivers')}>
                        {getFieldDecorator('drivers', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter the drivers!',
                            },
                          ],
                        })(
                          <Select mode="multiple">
                            {driversData &&
                              driversData.allDrivers &&
                              driversData.allDrivers.data.map((option, idx) => (
                                <Option key={idx} value={option.id}>
                                  {option.name}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </FormItem>

                      <Button type="primary" htmlType="submit">
                        {t('Submit')}
                      </Button>
                      <Button onClick={() => handleVisibleChange(false)}>
                        {t('Cancel')}
                      </Button>
                    </Form>
                  </Spin>
                  <Table
                    scroll={{ x: 400 }}
                    columns={columns}
                    dataSource={
                      tripData &&
                      tripData.getTrip &&
                      tripData.getTrip.routeLines.map((routeLine) => ({
                        ...routeLine,
                        key: routeLine.id,
                      }))
                    }
                  />
                </>
              );
            }}
          </Query>
        )}
      </>
    </>
  );
};

const EditTripInstanceWrapper = Form.create({})(EditTripInstance);

export default EditTripInstanceWrapper;