import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Modal, Form, Select, Spin } from 'antd';
import { AuthContext } from 'authContext';
import { formatTime, ValidateUser } from 'utilities';
import { GET_MY_SHIFTS, START_SHIFT, END_SHIFT, me } from 'services';
import { Can } from 'components';
import { notify } from 'utilities';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const { Option } = Select;

const LogShift = () => {
  ValidateUser();
  const authContext = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [t, i18n] = useTranslation();
  const nameLang = i18n.language;
  const [endShiftVisible, setEndShiftVisible] = useState(false);
  const [form] = Form.useForm();
  const [startShift, { loading: startingShift }] = useMutation(START_SHIFT);
  const [endShift, { loading: endingShift }] = useMutation(END_SHIFT);
  const { data, loading } = useQuery(GET_MY_SHIFTS, {
    fetchPolicy: 'network-only',
    variables: {
      first: 5,
    },

    notifyOnNetworkStatusChange: true,
  });
  const { data: userData } = useQuery(me, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  // submit the form for starting the current shift
  const onSubmit = (values) => {
    const { shift } = values;
    startShift({
      variables: {
        id: shift,
      },
    })
      .then((response) => {
        const {
          data: {
            startStationShift: { status, message},
          },
        } = response;
        // status is a boolean that is returned true from BE if no error occurs
        if (status) {
          notify('success', nameLang === "en" ? message : t("starting_shift"));
          authContext.refetchUser();
        } else if (status === 0 && message) {
          notify('error', message);
        }
      })
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        }
      });
    setVisible(false);
  };
  // end the current / ongoing shift
  const onEndingCurrentShift = () => {
    let id;
    if (userData?.me?.ongoing_shift.id) {
      id = userData.me.ongoing_shift.id;
    } else if (authContext?.user?.onGoingShift?.id) {
      id = authContext.user.onGoingShift.id;
    }
    if (id) {
      endShift({
        variables: {
          id,
        },
      })
        .then((response) => {
          const {
            data: {
              endStationShift: { status, message },
            },
          } = response;
          // status is a boolean that is returned true from BE if no error occurs
          if (status) {
            notify('success',  nameLang === "en" ? message : t("ending_shift"));
            authContext.refetchUser();
          }
        })
        .catch((err) => {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];

          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        });
    }

    // hide the confirming modal for ending the shift
    setEndShiftVisible(false);
  };
  return (
    <>
      
      {!authContext?.user.station ||
      data?.myTodayShifts?.length === 0 ||
      loading ? null : userData?.me?.ongoing_shift?.id ||
        authContext?.user?.onGoingShift?.id ? (
        <Can
          perform="END_MY_SHIFT"
          yes={
            <Button
              style={{
                padding: 0,
                marginRight: 10,
              }}
              danger
              type="link"
              onClick={() => {
                // confirm ending the current shift
                setEndShiftVisible(true);
              }}
              loading={endingShift}
            >
              {t('End Shift')}
            </Button>
          }
        />
      ) : (
        <Can
          perform="START_MY_SHIFT"
          yes={
            <Button
              style={{
                padding: 0,
                marginRight: 10,
              }}
              type="link"
              onClick={() => {
                setVisible(true);
              }}
              loading={startingShift}
            >
              {t('Start Shift')}
            </Button>
          }
        />
      )}

      <Modal
        visible={visible}
        title={t('Start Current Shift')}
        okText={t('Start')}
        cancelText={t('Cancel')}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        onOk={() => {
          form.validateFields().then((values) => {
            form.resetFields();
            onSubmit(values);
          });
        }}
      >
        <Form form={form} layout="inline" name="start_shift">
          <Item
            name="shift"
            label={t('Shift')}
            rules={[
              {
                required: true,
                message: t('Please, select the current shift'),
              },
            ]}
          >
            <Select
              placeholder={t('Select Current Shift')}
              size="large"
              showSearch
              optionFilterProp="children"
              allowClear
              style={{ width: 350 }}
            >
              {loading ? (
                <Option value={null} disabled style={{ textAlign: 'center' }}>
                  <Spin tip="Loading Shifts..." />
                </Option>
              ) : (
                data?.myTodayShifts.map((shift, index) => {
                  return (
                    <Option key={`${shift.id}_${index}`} value={shift.id}>{`${
                      shift.name
                    }_${shift.code} (${formatTime(
                      shift.start_time
                    )} - ${formatTime(shift.end_time)}) `}</Option>
                  );
                })
              )}
            </Select>
          </Item>
        </Form>
      </Modal>

      <Modal
        visible={endShiftVisible}
        title={t('End Current Shift')}
        okText={t('End')}
        cancelText={t('Cancel')}
        onCancel={() => {
          setEndShiftVisible(false);
        }}
        onOk={() => {
          onEndingCurrentShift();
        }}
      >
        {t('Are you sure you want to end the current shift?')}
      </Modal>
    </>
  );
};

export default LogShift;
