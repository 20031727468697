/**Component responsible for listing the available shipments that have
 * been created with view, edit and updating shipment status actions */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { Space, Table, Divider, Button, Modal, Form, Input } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ORDERS, UPDATE_SHIPPING_ORDER } from 'services';
import { ListHeader, PrimaryTitle, Can } from 'components';
import { notify, ValidateUser } from 'utilities';
import Filter from './filter';
import CANCEL_SHIPPING_ORDER from '../../services/shipments/cancel-shipping-order';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const ShipmentsListContainer = styled.div``;
const SearchContainer = styled.div`
  border-bottom: 1px solid #9b9b9b;
  padding: 10px;
  .ant-form,
  .ant-form-inline {
    margin: 20px 0;
  }
  .ant-form label {
    font-size: 16px;
  }
  .selectStation {
  }
  .button-list {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    margin-top: 14px;
  }
`;

const { Item } = Form;

const ShipmentsList = () => {
  ValidateUser();
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const nameLang = i18n.language;
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { data, loading, fetchMore, refetch } = useQuery(GET_ORDERS, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [activeToCancel, setActiveToCancel] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [
    updateShippingOrder,
    { loading: updateShippingOrderLoading },
  ] = useMutation(UPDATE_SHIPPING_ORDER);
  const [
    cancelShippingOrder,
    { loading: cancelShippingOrderLoading },
  ] = useMutation(CANCEL_SHIPPING_ORDER, {
    onError: (err) => {
      setConfirmationDialog(false);
      if (err['graphQLErrors'][0]) {
        const { message } = err['graphQLErrors'][0];
        notify('error', message);
      }
    },
  });

  /**Map the current label of order status to modified titles */
  const updateOrderStatusLabel = (orderStatus) => {
    switch (orderStatus) {
      case 'PENDING':
        return t('Dispatch');
      case 'DISPATCHED':
        return t('Receive');
      case 'RECEIVED':
        return t('Deliver');
      case 'DELIVERED':
        return 'Delivered';
      default:
        return `${data?.order?.status?.label}`;
    }
  };

  /** Check if the shipment order is in the last state (deliever)
   * and add the reciever national id then perform an update to the
   * next order state  */
  const updateOrderStatus = (order, receiverNationalId) => {
    let orderStatusInputValues = {
      id: order.id,
    };
    if (receiverNationalId) {
      orderStatusInputValues = {
        ...orderStatusInputValues,
        receiverNationalId,
      };
    }

    updateShippingOrder({
      variables: orderStatusInputValues,
    })
      .then((response) => {
        const {
          data: {
            ShippingOrderApplyNextAction: { status, message },
          },
        } = response;
        // status is a boolean that is returned true from BE if no error occurs
        if (status) {
          notify('success', message);
          refetch();
        } else if (status === 0 && message) {
          notify('error', message);
        }
      })
      .catch((err) => {
        if (err['graphQLErrors'] && err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
            debugMessage,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else if (debugMessage) {
            notify('error', debugMessage);
          } else {
            notify('error', message);
          }
        }
      });
  };

  /** Submit the form containing the reciever national id */
  const onSubmit = (values) => {
    const { receiver_national_id } = values;
    updateOrderStatus(selectedOrder, receiver_national_id);
    setVisible(false);
  };

  /** Available columns for listing shipment orders */
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Order Number',
      dataIndex: 'order_number',
    },
    {
      title: 'Trip Id',
      dataIndex: ['trip_route_line', 'trip', 'id'],
    },
    {
      title: 'Trip Code',
      dataIndex: ['trip_route_line', 'trip', 'ref_code'],
    },
    {
      title: 'Departure Station',
      dataIndex: ['from_location',  nameLang === "en" ? 'name_en' : 'name_ar'],
    },
    {
      title: 'Arrival Station',
      dataIndex: ['to_location',  nameLang === "en" ? 'name_en' : 'name_ar'],
    },
    {
      title: 'Status',
      // dataIndex: ['status', 'label'],
      render: (record)=> t(record.status.label),
    },
    {
      title: 'Total Price',
      dataIndex: ['total_price'],
    },
    {
      title: 'Created By',
      dataIndex: ['creatable', 'phone'],
    },
    {
      title: 'Created At',
      dataIndex: ['created_at'],
    },
    {
      title: 'Received By',
      dataIndex: ['receivedBy', 'name'],
    },
    {
      title: 'Dispatched By',
      dataIndex: ['dispatchedBy', 'name'],
    },
    {
      title: 'Actions',
      render: (order) => {
        const orderStatus = order?.status?.value;
        const ViewOrderButton = (
          <Link to={`/shipping-order/${order?.id}`}>{t('View')}</Link>
        );

        const EditOrderButton = (
          <>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                history.push({
                  pathname: `/edit-shipment/${order?.id}`,
                  state: { order },
                });
              }}
            >
              {t('Edit')}
            </Button>
          </>
        );

        const updateOrderStatusButton = () => {
          if (orderStatus !== 'CANCELLED')
            return (
              <>
                <Divider type="vertical" />
                <Button
                  loading={
                    selectedOrder?.id === order?.id
                      ? updateShippingOrderLoading
                      : false
                  }
                  type="link"
                  disabled={orderStatus === 'DELIVERED'}
                  onClick={() => {
                    setSelectedOrder(order);
                    if (
                      orderStatus === 'RECEIVED' &&
                      order?.next_action_label === 'Deliver'
                    ) {
                      setVisible(true);
                    } else {
                      updateOrderStatus(order);
                    }
                  }}
                >
                  {updateOrderStatusLabel(orderStatus)}
                </Button>
              </>
            );
          else return '';
        };
        const CancelOrderButton = (
          <>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={orderStatus !== 'PENDING'}
              onClick={() => {
                setActiveToCancel(order?.id);
                setConfirmationDialog(true);
              }}
            >
              {t('Cancel')}
            </Button>
          </>
        );

        return (
          <>
            <Can perform="CAN_VIEW_SHIPPING_ORDER" yes={ViewOrderButton} />
            <Can perform="CAN_EDIT_SHIPPING_ORDER" yes={EditOrderButton} />
            <Can perform="CANCEL_SHIPPING_ORDER" yes={CancelOrderButton} />
            <Can
              perform="CAN_APPLY_SHIPPING_ORDER_NEXT_ACTION"
              yes={updateOrderStatusButton}
            />
          </>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  return (
    <ShipmentsListContainer>
      <ListHeader>
        <PrimaryTitle>{t('Shipping Orders')}</PrimaryTitle>
      </ListHeader>
      <SearchContainer>
        <Filter
          refetch={refetch}
          resetPage={() => {
            setPage(1);
          }}
        />
      </SearchContainer>
      {/*cancel shipping order confirmation*/}
      <Modal
        destroyOnClose
        visible={confirmationDialog}
        title={t('Cancel Shipping Order')}
        okText={t('Submit')}
        cancelText={t('Cancel')}
        onCancel={() => {
          setConfirmationDialog(false);
        }}
        confirmLoading={cancelShippingOrderLoading}
        onOk={() => {
          cancelShippingOrder({ variables: { id: activeToCancel } })
            .then((response) => {
              setPage(page);
              refetch({ page: page });
              setConfirmationDialog(false);
              const {
                data: {
                  CancelShippingOrder: { status, message },
                },
              } = response;
              // status is a boolean that is returned true from BE if no error occurs
              if (status) {
                notify('success', message);
              } else if (status === 0 && message) {
                notify('error', message);
              }
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <h2>
          {t('Cancelling Shipping order')} # {activeToCancel}
        </h2>
      </Modal>
      <Table
        scroll={{ x: 400 }}
        bordered
        dataSource={data?.shippingOrdersList.data}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: data?.shippingOrdersList?.paginatorInfo?.total + 1,
          pageSize: 5,
          current: page,
          showSizeChanger: false,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />

      <Modal
        destroyOnClose
        visible={visible}
        title={t('Receiver National ID')}
        okText={t('Submit')}
        cancelText={t('Cancel')}
        onCancel={() => {
          setVisible(false);
        }}
        confirmLoading={updateShippingOrderLoading}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onSubmit(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          name="receiver_id_form"
          layout={isMobile ? 'horizontal' : 'inline'}
          preserve={false}
          initialValues={{ modifier: 'public' }}
        >
          <Item
            name="receiver_national_id"
            label="National ID"
            rules={[
              {
                required: true,
                message: 'Please, enter a valid National ID or Passport Number',
                pattern: new RegExp('^[a-zA-Z0-9]+$'),
              },
            ]}
          >
            <Input placeholder="e.g. 12345678999999" size="large" />
          </Item>
        </Form>
      </Modal>
    </ShipmentsListContainer>
  );
};

export default ShipmentsList;
