import gql from 'graphql-tag';

export const FETCH_ORDER_DETAILS = gql`
  query order($orderId: ID!) {
    order(id: $orderId) {
      id
      customer {
        name
        phone
      }
      price
      payment_method
      order_number
      created_at
      kiosk_number
      status
      tickets {
        id
        uuid
        code
        from_time
        to_time
        from_date
        from_location {
          name_en
          name_ar
          city {
            name_en
            name_ar
          }
        }
        to_location {
          name_en
          name_ar
          city {
            name_en
            name_ar
          }
        }
        seat_number
        seat_type {
          name_en
          name_ar
        }
        price
        status
        trip {
          ref_code
          date
          time
        }
      }
      updated_at
    }
  }
`;
