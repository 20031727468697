/**Component that displays a grid containing numbered seats based on the number
 * of rows and coloumns entered.
 */
import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import { DriverIcon, SeatIcon, WcIcon } from 'components';

const GridContainer = styled.div`
  flex: 1 1 0px;
  max-width: 50%;
  margin-right: 20px;
`;

const RowContainer = styled.div``;

const SeatsGrid = styled.div`
  text-align: center;
  width: 100%;
  display: inline-block;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 15px;
  padding: 10px;
  p {
    width: 25px;
    height: 25px;
    margin: 0 auto;
    border-radius: 100px;
  }
`;

const StyleIcon = css`
  display: inline-block;
  margin: 10px 12px;
  background-color: transparent;
  vertical-align: top;
  padding: 0;
  border: none;
  text-align: center;
`;

const Numbering = styled.p``;

const Seat = styled.button`
  ${StyleIcon}
  fill: ${(props) => props.color};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  position: relative;

  svg {
    width: 35px;
    height: 35px;
    transition: width 0.2s, height 0.2s;

    &:hover {
      width: 50px;
      height: 50px;
    }

    :active {
      fill: blue;
    }
  }
`;

const ColorLegend = styled.div`
  display: block;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 20px;
`;

const Dot = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 1000px;
  background-color: ${(props) => props.color};
`;

const Item = styled.div`
  display: inline-block;
  text-align: center;
  padding: 10px;

  p {
    margin: 0;
  }
`;

const NASeat = styled.div`
  display: inline-block;
  width: 35px;
  height: 35px;

  p {
    display: none;
  }
`;

const seatTypesColors = {
  Mini: 'red',
  Super: 'green',
  'Deluxe': 'brown',
  'Business' : 'lightgreen',
  'Mini Economy': 'blue',
  'Super Economy': 'black',
};

const BusSalon = ({ seats, allSeatTypes, selectedSeats, selectSeat }) => {
  const {t,i18n}= useTranslation();
  return (
    <>
      {seats.length > 0 && (
        <GridContainer>
          <ColorLegend>
            {Object.keys(seatTypesColors).map((type) => (
              <Item>
                <Dot color={seatTypesColors[type]}></Dot>
                <p>
                  {type === 'Mini'
                    ? t('Mini')
                    : type === 'Super'
                    ? t('Super')
                    : type === 'Business'
                    ? t('Business')
                    : type === 'Deluxe'
                    ? t('deluxe')
                    : type === 'Mini Economy'
                    ? t('mini_economy')
                    : type === 'Super Economy'
                    ? t('super_economy')
                    : ''}
                </p>
              </Item>
            ))}
          </ColorLegend>
          <SeatsGrid>
            {seats.map((row, index) => (
              <RowContainer key={index}>
                {row.map((seat) => {
                  const { key, numbering, seatType, type } = seat;
                  const { name_en: seatTypeName } = allSeatTypes.find(
                    (seat) => seat.id == seatType
                  );
                  return (
                    <Seat
                      type="button"
                      title={type === 'SEAT' ? seatTypeName : undefined}
                      key={key}
                      color={(() => {
                        const buttonStyling = {};
                        if (selectedSeats.includes(parseInt(key, 10))) {
                          return 'blue';
                        }
                        if (type === 'SEAT') {
                          return seatTypesColors[seatTypeName];
                        }

                        if (type === 'NA') return 'lightgray';

                        return {
                          ...buttonStyling,
                        };
                      })()}
                      onClick={() => {
                        selectSeat(key);
                      }}
                    >
                      {type === 'SEAT' && <SeatIcon />}
                      {type === 'WC' && <WcIcon />}
                      {type === 'NA' && <NASeat />}
                      {type === 'DRIVER' && <DriverIcon />}
                      <Numbering>{numbering}</Numbering>
                    </Seat>
                  );
                })}
              </RowContainer>
            ))}
          </SeatsGrid>
        </GridContainer>
      )}
    </>
  );
};

export default BusSalon;
