/** Component that lists all shipping details and
 * contain an action button for printing these details
 */
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, Divider, Row, Col } from 'antd';
import { GET_ORDER_DETAILS } from 'services';
import { useQuery } from '@apollo/react-hooks';
import { ListHeader, PrimaryButton, Can } from 'components';
import ReactToPrint from 'react-to-print';
import { ValidateUser } from '../../utilities';
import { ReactComponent as Logo } from 'images/logo.svg';
import { default as ShipmentRules } from './rules';
import { useTranslation } from 'react-i18next';
import  OrderDetails from './/images/shipping print Desgin-1.png';
import styled from '@emotion/styled';
import i18next from 'i18next';
const VoucherContainer = styled.div`

`;
const VoucherBoxOne = styled.div`
min-width: 100%;
display: flex;
justify-content: space-around;
position: absolute;
top: 100px;
transform: translate(2%,-2%);
flex-direction: row-reverse;
z-index: -100;
`
const VoucherBoxTwo = styled.div`
min-width: 100%;
display: flex;
justify-content: space-around;
position: absolute;
top: 100px;
transform: translate(2%,395%);
flex-direction: row-reverse;
z-index: -100;
`
const ColOne = styled.div`
  flex-direction: column;
  font-size: 13px;
  font-weight: bold;
  color: black;
  div p{
    margin:0px;
    padding-bottom: 4px;
  }

}
`
const ColTwo = styled.div`
flex-direction: column;
font-size: 13px;
font-weight: bold;
color: black;
div p{
  margin:0px;
  padding-bottom: 4px;
}

`
const ShipmentDetails = () => {
  ValidateUser();
  const [t,i18n]=useTranslation();
  const nameLang = i18n.language;
  const componentRef = useRef();
  const { orderId } = useParams();
  const {language} = i18next;
  const { data, loading } = useQuery(GET_ORDER_DETAILS, {
    variables: {
      id: orderId,
    },
  });

  return (
    <Spin spinning={loading}>
      <ListHeader>
        {data?.shippingOrder?.status?.value != 'CANCELLED' && (
          <Can
            perform="CAN_VIEW_SHIPPING_ORDER"
            yes={
              <ReactToPrint
                trigger={() => (
                  <PrimaryButton clean>
                    {t('Print Order Details')}
                  </PrimaryButton>
                )}
                content={() => componentRef.current}
              />
            }
          />
        )}
      </ListHeader>
      <div>
        <div style={{ padding: '35px' }}>
          <Row style={{ margin: '-10px -10px 10px -25px' }}>
            <Col span={12}>
              <Logo style={{ height: '25px', transform: 'translateY(50%)' }} />
            </Col>
            <Col span={12}>
              <h4 style={{ margin: '25px 0 0 0' }}>{t("Shipping Voucher")}</h4>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Order Number")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.order_number}
              </p>
            </Col>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Issue Date")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {new Date(data?.shippingOrder?.created_at).toLocaleDateString(
                  'en-EG',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }
                )}
              </p>
            </Col>
          </Row>

          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Employee")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.creatable?.name}
              </p>
            </Col>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>Store</h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>{data?.shippingOrder?.store}</p>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Sender Name")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.sender?.name}
              </p>
            </Col>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Receiver Name")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.receiver?.name}
              </p>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Sender Phone")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.sender?.phone}
              </p>
            </Col>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Receiver Phone")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.receiver?.phone}
              </p>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Sender National ID")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.sender?.national_id}
              </p>
            </Col>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                Receiver National ID
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.receiver?.national_id}
              </p>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("From City")}
              </h5>
            </Col>
            <Col span={6}>
              {' '}
              <p style={{ fontSize: '11px' }}>
              {nameLang === "en" ? data?.shippingOrder?.from_location?.name_en : data?.shippingOrder?.from_location?.name_ar}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>{t("To City")}</h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {nameLang === "en" ? data?.shippingOrder?.to_location?.name_en : data?.shippingOrder?.to_location?.name_ar}
                </p>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
              {t("Description")}
              </h5>
            </Col>
            {data?.shippingOrder?.items?.map((item) => {
              return (
                <Col span={4}>
                  <p style={{ fontSize: '11px' }}>{item.name}</p>
                </Col>
              );
            })}
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5
                style={{
                  fontSize: '11px',
                  fontWeight: '400px',
                  marginTop: '2px',
                }}
              >
               {t("Price")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px', marginTop: '2px' }}>
                EGP{data?.shippingOrder?.total_price}
              </p>
            </Col>
          </Row>
          <ShipmentRules></ShipmentRules>
        </div>
        <div style={{ padding: '35px', borderTop: '2px dashed' }}>
          <Row style={{ margin: '-10px -10px 10px -25px' }}>
            <Col span={12}>
              <Logo style={{ height: '25px', transform: 'translateY(50%)' }} />
            </Col>
            <Col span={12}>
              <h4 style={{ margin: '25px 0 0 0' }}>{t("Shipping Voucher")}</h4>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Order Number")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.order_number}
              </p>
            </Col>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Issue Date")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {new Date(data?.shippingOrder?.created_at).toLocaleDateString(
                  'en-EG',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }
                )}
              </p>
            </Col>
          </Row>

          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Employee")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.creatable?.name}
              </p>
            </Col>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>Store</h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>{data?.shippingOrder?.store}</p>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
               {t("Sender Name")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.sender?.name}
              </p>
            </Col>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Receiver Name")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.receiver?.name}
              </p>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Sender Phone")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.sender?.phone}
              </p>
            </Col>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Receiver Phone")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.receiver?.phone}
              </p>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Sender National ID")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.sender?.national_id}
              </p>
            </Col>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {t("Receiver National ID")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px' }}>
                {data?.shippingOrder?.receiver?.national_id}
              </p>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
                {("From City")}
                </h5>
              </Col>
              <Col span={6}>
                {' '}
                <p style={{fontSize: '11px'}}>
                  {nameLang === "en" ? data?.shippingOrder?.from_location?.name_en : data?.shippingOrder?.from_location?.name_ar}
                </p>
              </Col>
              <Col span={6}>
                <h5 style={{fontSize: '11px', fontWeight: '400px'}}>{t("To City")}</h5>
              </Col>
              <Col span={6}>
                <p style={{fontSize: '11px'}}>
                  {nameLang === "en" ? data?.shippingOrder?.to_location?.name_en : data?.shippingOrder?.to_location?.name_ar}
              </p>
            </Col>
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5 style={{ fontSize: '11px', fontWeight: '400px' }}>
              {t("Description")}
              </h5>
            </Col>
            {data?.shippingOrder?.items?.map((item) => {
              return (
                <Col span={4}>
                  <p style={{ fontSize: '11px' }}>{item.name}</p>
                </Col>
              );
            })}
          </Row>
          <Row style={{ margin: -10 }}>
            <Col span={6}>
              <h5
                style={{
                  fontSize: '11px',
                  fontWeight: '400px',
                  marginTop: '2px',
                }}
              >
                {t("Price")}
              </h5>
            </Col>
            <Col span={6}>
              <p style={{ fontSize: '11px', marginTop: '2px' }}>
                EGP{data?.shippingOrder?.total_price}
              </p>
            </Col>
          </Row>
          <ShipmentRules></ShipmentRules>
        </div>
      </div>
      <VoucherContainer ref={componentRef} style={{ height: '2443px' , display:"" }} dir="rtl">
          <img src={OrderDetails} alt="OrderDetails"  style={{width:"100%" , height:"65%", zIndex:"-100", position:"relative"}}/>
        <VoucherBoxOne>
          <ColOne className="one" dir="rtl">
            <div >
              <p>{data?.shippingOrder?.order_number}</p>
            </div>
            <div s>
              <p>{data?.shippingOrder?.creatable?.name}</p>
            </div>
            <div >
              <p>{data?.shippingOrder?.sender?.name}</p>
            </div>
            <div >
              <p>{data?.shippingOrder?.sender?.phone}</p>
            </div>
            <div >
              <p>{data?.shippingOrder?.sender?.national_id}</p>
            </div>
            <div >
              <p>{data?.shippingOrder?.to_location?.name_en}</p>
            </div>
          </ColOne>

          <ColTwo className="two" dir="rtl">
            <div span={6}>
              <p>
                {new Date(data?.shippingOrder?.created_at).toLocaleDateString(
                  'en-EG',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }
                )}
              </p>
            </div>
            <div span={6}>
              <p>{data?.shippingOrder?.store}</p>
            </div>
            <div span={6}>
              <p>{data?.shippingOrder?.receiver?.name}</p>
            </div>
            <div span={6}>
              <p>{data?.shippingOrder?.sender?.phone}</p>
            </div>
            <div span={6}>
              <p>{data?.shippingOrder?.receiver?.national_id}</p>
            </div>
            <div span={6}>
              {' '}
              <p>{data?.shippingOrder?.from_location?.name_en}</p>
            </div>
            {data?.shippingOrder?.items?.map((item) => {
              return (
                <div span={4}>
                  <p>{item.name}</p>
                </div>
              );
            })}
            <div span={6}>
              <p>EGP{data?.shippingOrder?.total_price}</p>
            </div>
          </ColTwo>
          </VoucherBoxOne>
        <VoucherBoxTwo>
          <ColOne className="one" dir="rtl">
            <div >
              <p>{data?.shippingOrder?.order_number}</p>
            </div>
            <div s>
              <p>{data?.shippingOrder?.creatable?.name}</p>
            </div>
            <div >
              <p>{data?.shippingOrder?.sender?.name}</p>
            </div>
            <div >
              <p>{data?.shippingOrder?.sender?.phone}</p>
            </div>
            <div >
              <p>{data?.shippingOrder?.sender?.national_id}</p>
            </div>
            <div >
              <p>{data?.shippingOrder?.to_location?.name_en}</p>
            </div>
          </ColOne>

          <ColTwo className="two" dir="rtl">
            <div span={6}>
              <p>
                {new Date(data?.shippingOrder?.created_at).toLocaleDateString(
                  'en-EG',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }
                )}
              </p>
            </div>
            <div span={6}>
              <p>{data?.shippingOrder?.store}</p>
            </div>
            <div span={6}>
              <p>{data?.shippingOrder?.receiver?.name}</p>
            </div>
            <div span={6}>
              <p>{data?.shippingOrder?.sender?.phone}</p>
            </div>
            <div span={6}>
              <p>{data?.shippingOrder?.receiver?.national_id}</p>
            </div>
            <div span={6}>
              {' '}
              <p>{data?.shippingOrder?.from_location?.name_en}</p>
            </div>
            {data?.shippingOrder?.items?.map((item) => {
              return (
                <div span={4}>
                  <p>{item.name}</p>
                </div>
              );
            })}
            <div span={6}>
              <p>EGP{data?.shippingOrder?.total_price}</p>
            </div>
          </ColTwo>
          </VoucherBoxTwo>
      </VoucherContainer>
    </Spin>
  );
};

export default ShipmentDetails;
