/**Creating/Editing a shipment order form */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Form,
  Input,
  Button,
  Select,
  Spin,
  Row,
  Col,
  Popconfirm,
  Typography,
  InputNumber,
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import { ListHeader } from 'components';
import { notify, FormContainer, ActionButton, CancelButton } from 'utilities';
import {
  GET_CATEGORIES,
  CREATE_SHIPPING_ORDER,
  EDIT_SHIPPING_ORDER,
} from 'services';
import { useTranslation } from 'react-i18next';

const { Item, List } = Form;
const { Option } = Select;
const { Title } = Typography;

const ShipmentInfoContainer = styled.section`
  ${FormContainer}
  width: 100%;
  margin-top: 15px;
  ${ActionButton}
  ${CancelButton}
`;

const InfoContainer = styled.div`
  margin: 15px 0;
  background-color: #8080800a;
  padding: 15px;
`;

const ShipmentInfoForm = ({ selectedTrip, order, searchValues }) => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const [form] = Form.useForm();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [itemAdded, setItemAdded] = useState(null);
  const [updatedFields, setUpdatedFields] = useState(null);
  const { data: categories, loading: loadingCategories } = useQuery(
    GET_CATEGORIES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );
  const [
    createShippingOrder,
    { loading: createShippingOrderLoading },
  ] = useMutation(CREATE_SHIPPING_ORDER);

  const [
    editShippingOrder,
    { loading: editShippingOrderLoading },
  ] = useMutation(EDIT_SHIPPING_ORDER);

  useEffect(() => {
    if (order) {
      let totalItemQuantity = 0;
      const totalItems = order?.items?.map((item) => {
        if (item.quantity) {
          totalItemQuantity += item.quantity;
        }
      });
      setTotalQuantity(totalItemQuantity);
      setTotalPrice(order?.total_price);
      setItemAdded(true);
    }
  }, [order]);

  useEffect(() => {
    let totalItemPrice = 0;
    let totalItemQuantity = 0;
    if (form.getFieldsValue('items')?.items?.length > 0) {
      setItemAdded(true);
      const newItems = form.getFieldsValue('items')?.items?.map((item) => {
        if (item.price) {
          totalItemPrice = item.quantity
            ? totalItemPrice + item.quantity * item.price
            : totalItemPrice + item.price;
        }
        if (item.quantity) {
          totalItemQuantity += item.quantity;
        }
      });
    } else {
      setItemAdded(false);
    }
    setTotalPrice(totalItemPrice);
    setTotalQuantity(totalItemQuantity);
  }, [updatedFields, form]);

  const updateTotatlSummary = () => {
    let totalItemPrice = 0;
    let totalItemQuantity = 0;
    if (form.getFieldsValue('items')?.items?.length > 0) {
      const newItems = form.getFieldsValue('items')?.items?.map((item) => {
        if (item.price) {
          totalItemPrice = item.quantity
            ? totalItemPrice + item.quantity * item.price
            : totalItemPrice + item.price;
        }
        if (item.quantity) {
          totalItemQuantity += item.quantity;
        }
      });

      setTotalPrice(totalItemPrice);
      setTotalQuantity(totalItemQuantity);
    }
  };

  // submit form items (order) for shipment
  const onFinish = (values) => {
    if (order) {
      const updatedItems = values?.items?.map((item) => {
        return {
          id: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          initial_weight: item.initial_weight,
          extra_weight: item.extra_weight,
        };
      });
      const editShippingOrderValues = {
        ...values,
        items: updatedItems,
        id: order?.id,
        total_price: totalPrice,
      };
      editShippingOrder({
        variables: {
          input: editShippingOrderValues,
        },
      })
        .then((response) => {
          const {
            data: {
              editShippingOrder: { status, message, data },
            },
          } = response;
          // status is a boolean that is returned true from BE if no error occurs
          if (status) {
            notify('success', message);
            history.push(`/shipping-order/${data.id}`);
          } else if (status === 0 && message) {
            notify('error', message);
          }
        })
        .catch((err) => {
          if (err['graphQLErrors'] && err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
              debugMessage,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else if (debugMessage) {
              notify('error', debugMessage);
            } else {
              notify('error', message);
            }
          }
        });
    } else {
      const createShippingOrderValues = {
        ...values,
        total_price: totalPrice,
        trip_route_line_id: selectedTrip?.routeLine?.id,
        from_location_id: searchValues.from,
        to_location_id: searchValues.to,
      };
      createShippingOrder({
        variables: {
          input: createShippingOrderValues,
        },
      })
        .then((response) => {
          const {
            data: {
              createShippingOrder: { status, message, data },
            },
          } = response;
          // status is a boolean that is returned true from BE if no error occurs
          if (status) {
            notify('success', message);
            history.push(`/shipping-order/${data.id}`);
          } else if (status === 0 && message) {
            notify('error', message);
          }
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
              debugMessage,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else if (debugMessage) {
              notify('error', debugMessage);
            } else {
              if (message == 'Internal server error')
                notify('error', 'Sender or receiver got invalid data.');
              else notify('error', message);
            }
          }
        });
    }
  };

  const onItemsUpdate = (fields) => {
    setUpdatedFields(fields);
  };

  const onChange = () => {
    updateTotatlSummary();
  };

  return (
    <ShipmentInfoContainer>
      <Spin
        spinning={
          loadingCategories ||
          createShippingOrderLoading ||
          editShippingOrderLoading
        }
      >
        <Form
          name="dynamic_form_nest_item"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          onChange={onChange}
          initialValues={
            order
              ? {
                  sender_name: order?.sender?.name,
                  sender_phone: order?.sender?.phone,
                  sender_national_id: order?.sender?.national_id,
                  receiver_name: order?.receiver?.name,
                  receiver_phone: order?.receiver?.phone,
                  receiver_national_id: order?.receiver?.national_id,
                  items: order?.items?.map((item) => {
                    const updatedItem = {
                      id: item.id,
                      name: item.name,
                      shipping_category_id: item.category.id,
                      initial_weight: item.initial_weight
                        ? item.initial_weight
                        : null,
                      extra_weight: item.extra_weight
                        ? item.extra_weight
                        : null,
                      quantity: item.quantity,
                      price: item.price,
                      editedItem: true,
                    };
                    return updatedItem;
                  }),
                }
              : null
          }
        >
          <InfoContainer>
            <Title level={2}>{t('Sender Information:')}</Title>
            <Row gutter={10}>
              <Col span={12}>
                <Item
                  name="sender_name"
                  label={t('Full Name')}
                  rules={[
                    {
                      required: true,
                      message: t('Please, enter the sender name!'),
                    },
                  ]}
                >
                  <Input placeholder="e.g. John Doe" size="large" />
                </Item>
              </Col>

              <Col span={12}>
                <Item
                  name="sender_phone"
                  label={t('Phone Number')}
                  rules={[
                    {
                      required: true,
                      message: t('Please, enter a valid phone number!'),
                      len: 11,
                      // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
                    },
                  ]}
                >
                  <Input placeholder="e.g. 01123456789" size="large" />
                </Item>
              </Col>

              <Col span={12}>
                <Item
                  name="sender_national_id"
                  label={t('National ID / Passport Number')}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'Please, enter a valid National ID or Passport Number'
                      ),
                      pattern: new RegExp('^[a-zA-Z0-9]+$'),
                    },
                  ]}
                >
                  <Input placeholder="e.g. 1234567899999Aa" size="large" />
                </Item>
              </Col>
            </Row>
          </InfoContainer>
          <InfoContainer>
            <Title level={2}>{t('Reciever Information:')}</Title>
            <Row gutter={10}>
              <Col span={12}>
                <Item
                  name="receiver_name"
                  label={t('Full Name')}
                  rules={[
                    {
                      required: true,
                      message: t('Please, enter the reciever name!'),
                    },
                  ]}
                >
                  <Input placeholder="e.g. John Doe" size="large" />
                </Item>
              </Col>

              <Col span={12}>
                <Item
                  name="receiver_phone"
                  label={t('Phone Number')}
                  rules={[
                    {
                      required: true,
                      message: t('Please, enter a valid phone number!'),
                      len: 11,
                      // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
                    },
                  ]}
                >
                  <Input placeholder="e.g. 01123456789" size="large" />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  name="receiver_national_id"
                  label={t('National ID / Passport Number')}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'Please, enter a valid National ID or Passport Number'
                      ),
                      pattern: new RegExp('^[a-zA-Z0-9]+$'),
                    },
                  ]}
                >
                  <Input placeholder="e.g. 123456789999Aa" size="large" />
                </Item>
              </Col>
            </Row>
          </InfoContainer>
          <InfoContainer>
            <List name="items">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <ListHeader>
                      <Title level={2}>{t('Items Information:')}</Title>
                      {!order && (
                        <Button
                          style={{ marginTop: '30px', marginBottom: '30px' }}
                          onClick={() => {
                            add();
                            setItemAdded(true);
                          }}
                          type="primary"
                        >
                          {t('Add Item')}
                        </Button>
                      )}
                    </ListHeader>
                    {fields.map((field, index) => (
                      <Row
                        gutter={10}
                        key={field.name}
                        style={{
                          padding: '10px 20px',
                          backgroundColor: '#add8e642',
                          marginBottom: '30px',
                          position: 'relative',
                        }}
                      >
                        {(!order ||
                          (order && field.key >= order?.items?.length)) && (
                          <Popconfirm
                            title={t("Are you sure delete this item?")}
                            onConfirm={() => {
                              remove(field.name);
                              onItemsUpdate(fields);
                            }}
                            onCancel={() => {}}
                            okText={t('Yes')}
                            cancelText={t('No')}
                          >
                            <CloseCircleOutlined
                              style={{
                                color: 'red',
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                              }}
                            />
                          </Popconfirm>
                        )}
                        <Col span={8}>
                          <Item
                            {...field}
                            name={[field.name, 'name']}
                            fieldKey={[field.fieldKey, 'name']}
                            label={t('Item Name')}
                            rules={[
                              {
                                required: true,
                                message: t('Please, enter item name'),
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. Luggage Bag"
                              size="large"
                            />
                          </Item>
                        </Col>
                        <Col span={8}>
                          <Item
                            {...field}
                            name={[field.name, 'shipping_category_id']}
                            fieldKey={[field.fieldKey, 'shipping_category_id']}
                            label={t('Item Category')}
                            rules={[
                              {
                                required: true,
                                message: t('Please, select item category'),
                              },
                            ]}
                          >
                            <Select
                              placeholder={t('Select Item Category')}
                              size="large"
                              showSearch
                              optionFilterProp="children"
                              allowClear
                              // disabled={
                              //   order && field.key < order?.items?.length
                              //     ? true
                              //     : false
                              // }
                            >
                              {loadingCategories ? (
                                <Option
                                  value={null}
                                  disabled
                                  style={{ textAlign: 'center' }}
                                >
                                  <Spin tip="Loading Categories..." />
                                </Option>
                              ) : (
                                categories?.shippingCategories.map(
                                  (category) => {
                                    return (
                                      <Option
                                        key={category.id}
                                        value={category.id}
                                      >
                                        {category.name}
                                      </Option>
                                    );
                                  }
                                )
                              )}
                            </Select>
                          </Item>
                        </Col>
                        <Col span={8}>
                          <Item
                            {...field}
                            name={[field.name, 'initial_weight']}
                            fieldKey={[field.fieldKey, 'initial_weight']}
                            label={t('Weight/Item (KG)')}
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              placeholder="e.g. 20"
                              size="large"
                            />
                          </Item>
                        </Col>
                        <Col span={8}>
                          <Item
                            {...field}
                            name={[field.name, 'extra_weight']}
                            fieldKey={[field.fieldKey, 'extra_weight']}
                            label={t('Extra Weight/Item (KG)')}
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              placeholder="e.g. 5"
                              size="large"
                            />
                          </Item>
                        </Col>
                        <Col span={8}>
                          <Item
                            {...field}
                            name={[field.name, 'quantity']}
                            fieldKey={[field.fieldKey, 'quantity']}
                            label={t('Item Quantity')}
                            rules={[
                              {
                                pattern: new RegExp('^([d]*[1-9]+[0-9]*)$'),
                                required: true,
                                message: t(
                                  'Please, enter quantity greater than 0'
                                ),
                              },
                            ]}
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              placeholder="1"
                              size="large"
                              onChange={() => {
                                updateTotatlSummary();
                              }}
                            />
                          </Item>
                        </Col>
                        <Col span={8}>
                          <Item
                            {...field}
                            name={[field.name, 'price']}
                            fieldKey={[field.fieldKey, 'price']}
                            label={t('Item Price (L.E.)')}
                            rules={[
                              {
                                pattern: new RegExp('^([d]*[1-9]+[0-9]*)$'),
                                required: true,
                                message: t(
                                  'Please, enter item price greater than 0'
                                ),
                              },
                            ]}
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              placeholder="e.g. 100"
                              size="large"
                              onChange={() => {
                                updateTotatlSummary();
                              }}
                            />
                          </Item>
                        </Col>
                      </Row>
                    ))}
                    <InfoContainer>
                      <Title level={2}>{t('Total Summary:')}</Title>
                      <div>
                        {t('Total Number of Items:')} {totalQuantity}
                      </div>
                      <div>
                        {t('Total Price:')} {totalPrice} {t('L.E.')}
                      </div>
                    </InfoContainer>
                  </>
                );
              }}
            </List>
          </InfoContainer>
          {itemAdded && (
            <Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={
                  order ? editShippingOrderLoading : createShippingOrderLoading
                }
              >
                {order
                  ? t('Update Shipment Order')
                  : t('Create Shipment Order')}
              </Button>
            </Item>
          )}
        </Form>
      </Spin>
    </ShipmentInfoContainer>
  );
};

export default ShipmentInfoForm;
