/** This file is contains Listing table for partners segments,
 * actions buttons for add new partner, adding new segments and
 * exporting segments data*/
import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import {
  Button,
  Space,
  Table,
  Modal,
  Form,
  Divider,
  Select,
  Empty,
  Spin,
  InputNumber,
  Input,
} from 'antd';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  ASSIGN_PARTNERS,
  GET_SEGMENTS,
  SEARCH_USERS,
  ADD_SEGMENT_CREDIT,
  EXPORT_SEGMENTS_REPORT,
  EXPORT_SEGMENTS_TRANSACTIONS_REPORT,
  EXPORT_SEGMENTS_CREDITS_REPORT,
  TOGGLE_AGENT,
} from 'services';
import { useDebounce, notify, ValidateUser } from 'utilities';
import { ListHeader, PrimaryTitle, PrimaryButton, Can } from 'components';
import { AuthContext } from 'authContext';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const { Option } = Select;

const SegmentsListContainer = styled.div``;

const SegmentsList = () => {
  ValidateUser();
  const history = useHistory();
  const [form] = Form.useForm();
  const [addCreditForm] = Form.useForm();
  const authContext = useContext(AuthContext);
  const [segment, setSegment] = useState(0);
  const [visible, setVisible] = useState(false);
  const [addCreditVisible, setAddCreditVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [from, setFrom] = useState('');
  const [id, setID] = useState('');
  const [to, setTo] = useState('');
  const [status, setStatus] = useState(0);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [t, i18n] = useTranslation();

  /** search for users given phone number and partner role  */
  const [searchUsers, { loading: fetchingUsers, data: users }] = useLazyQuery(
    SEARCH_USERS,
    {
      variables: {
        userPhoneNumber: debouncedSearchTerm,
        role: 'PARTNER',
      },
    }
  );

  /** call search users whenever the phone number is given */
  useEffect(() => {
    if (debouncedSearchTerm) {
      searchUsers();
    }
  }, [debouncedSearchTerm, searchUsers]);

  /** query for listing all segments */
  const { data, loading, fetchMore } = useQuery(GET_SEGMENTS, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  /** query for adding credit to a selected segment */
  const [
    addPartnerSegmentCredit,
    { loading: addingSegmentCredit },
  ] = useMutation(ADD_SEGMENT_CREDIT, { awaitRefetchQueries: true });
  const [
    togglePartnerAgent,
    { loading: updatingAgent },
  ] = useMutation(TOGGLE_AGENT, { awaitRefetchQueries: true });

  /** query for assiging partners to a selected segment */
  const [assignPartners, { loading: assigning }] = useMutation(
    ASSIGN_PARTNERS,
    {
      awaitRefetchQueries: true,
    }
  );

  const handleCancel = () => {
    setExportModalVisible(false);
    setFrom('');
    setTo('');
    setStatus(0);
  };

  /** exporting data in a downloadable file */
  const [exportReport, { loading: exporting }] = useMutation(
    EXPORT_SEGMENTS_REPORT
  );
  const [exportTransactionsReport] = useMutation(
    EXPORT_SEGMENTS_TRANSACTIONS_REPORT
  );
  const [exportCreditsReport] = useMutation(EXPORT_SEGMENTS_CREDITS_REPORT);
  const handleTransactionsExport = (id, from, to) => {
    exportTransactionsReport({
      variables: {
        id: id,
        from: from,
        to: to,
      },
    })
      .then((res) => {
        const {
          data: {
            exportPartnerSegmentsTransactions: { message, status },
          },
        } = res;
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
  };
  const handleCreditsExport = (id, from, to) => {
    exportCreditsReport({
      variables: {
        id: id,
        from: from,
        to: to,
      },
    })
      .then((res) => {
        const {
          data: {
            exportPartnerSegmentsCredits: { message, status },
          },
        } = res;
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
  };
  /** Cloumns Names for segments table */
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Agents Count',
      dataIndex: 'agents_count',
    },
    {
      title: 'Tickets Count',
      dataIndex: 'tickets_count',
    },
    {
      title: 'Current Balance',
      key: 'currentBalance',
      render: (segment) => {
        return `${segment.credit_balance} LE`;
      },
    },
    {
      title: 'Total Balance',
      key: 'totalBalance',
      render: (segment) => {
        return `${segment.credit_total_added} LE`;
      },
    },
    {
      title: 'Status',
      dataIndex: ['status', 'label'],
    },
    {
      title: 'Actions',
      render: (segment) => {
        // open model for adding credit to selected segment
        const AddCreditButton = (
          <Button
            style={{ height: 18, padding: 0 }}
            type="link"
            onClick={() => {
              setSegment(segment);
              setAddCreditVisible(true);
            }}
          >
            {t('Add Credit')}
          </Button>
        );

        return (
          <Space>
            <Can
              perform="ADD_PARTNER_SEGMENT_AGENT"
              yes={
                <>
                  <Button
                    style={{ height: 18, padding: 0 }}
                    type="link"
                    onClick={() => {
                      history.push({
                        pathname: `/new-user`,
                        state: { segment: segment },
                      });
                    }}
                  >
                    {t('Add Partner')}
                  </Button>
                </>
              }
            />
            <Can
              perform="ADD_PARTNER_SEGMENT_AGENT"
              yes={
                <>
                  <Button
                    style={{ height: 18, padding: 0 }}
                    type="link"
                    onClick={() => {
                      toggleAgent(segment.id);
                    }}
                  >
                    {t('change to')}{' '}
                    {segment.sub_agent ? t('Agent') : t('sub Agent')}
                  </Button>
                </>
              }
            />
            <Can perform="ADD_PARTNER_SEGMENT_CREDIT" yes={AddCreditButton} />
            <Button
              style={{ height: 18, padding: 0 }}
              type="link"
              onClick={() => {
                setExportModalVisible(true);
                setID(segment.id);
                setStatus(1);
              }}
            >
              {t('Export Transactions Report')}
            </Button>
            <Button
              style={{ height: 18, padding: 0 }}
              type="link"
              onClick={() => {
                setExportModalVisible(true);
                setID(segment.id);
                setStatus(2);
              }}
            >
              {t('Export Credits Report')}
            </Button>
            <a href={`/segments-view/${segment.id}`}>{t('View')}</a>
          </Space>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });
  /** Close current open model for adding partner to selected segment */
  const onCancel = () => {
    setVisible(false);
  };

  /** Submit the form after assigning new partners to selected segment */
  const onSubmit = (values) => {
    const { usersList } = values;

    assignPartners({
      variables: {
        segmentId: segment?.id,
        usersList,
      },
      refetchQueries: () => [
        {
          query: GET_SEGMENTS,
          variables: {
            page,
            first: 5,
          },
        },
      ],
    })
      .then((res) => {
        const {
          data: {
            addPartnerSegmentAgents: { message, status },
          },
        } = res;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
    setVisible(false);
  };

  /** Sumbit adding credit form (inside model) for selected segment
   * and refetch segments to get updated data
   */
  const onSubmitAddCreditForm = (values) => {
    const { amount } = values;

    addPartnerSegmentCredit({
      variables: {
        partnerSegmentId: segment.id,
        amount,
      },
      refetchQueries: () => [
        {
          query: GET_SEGMENTS,
          variables: {
            page,
            first: 5,
          },
        },
      ],
    })
      .then((res) => {
        const {
          data: {
            addPartnerSegmentCredit: { message, status },
          },
        } = res;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
        authContext.refetchUser();
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
    setAddCreditVisible(false);
  };
  const toggleAgent = (PartnerId) => {
    togglePartnerAgent({
      variables: {
        partnerSegmentId: PartnerId,
      },
      refetchQueries: () => [
        {
          query: GET_SEGMENTS,
          variables: {
            page,
            first: 5,
          },
        },
      ],
    })
      .then((res) => {
        const {
          data: {
            togglePartnerSegmentAgent: { message, status },
          },
        } = res;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
        authContext.refetchUser();
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
    setAddCreditVisible(false);
  };

  /** Export Segments data in a downloadable file */
  const handleExport = () => {
    exportReport()
      .then((res) => {
        const {
          data: {
            exportPartnerSegments: {
              message,
              status,
              data: { downloadable_link },
            },
          },
        } = res;
        let a = document.createElement('a');
        a.href = downloadable_link;
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
  };

  return (
    <SegmentsListContainer>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>
          {t('Partners Segments')}
        </PrimaryTitle>
        <Can
          perform="EXPORT_PARTNER_SEGMENT"
          yes={
            <Button onClick={() => handleExport()} type="primary" danger>
              {t('Export Summary Report')}
            </Button>
          }
        />

        {/* Check if the current logged in user can add a segment then redirects to add
        a new segment */}
        <Can
          perform="CREATE_PARTNER_SEGMENT"
          yes={
            <Button
              type="primary"
              onClick={() => history.push('/new-partner-segment')}
            >
              {t('Add Partner Segment')}
            </Button>
          }
        />
      </ListHeader>
      <Table
        scroll={{ x: 400 }}
        bordered
        dataSource={data?.partnerSegments.data}
        columns={columns}
        loading={loading || addingSegmentCredit || updatingAgent}
        rowKey="id"
        pagination={{
          total: data?.partnerSegments?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />

      <Modal
        destroyOnClose
        visible={visible}
        title={t('Assign Partners to Partner Segment')}
        okText={t('Assign')}
        cancelText={t('Cancel')}
        onCancel={onCancel}
        confirmLoading={assigning}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onSubmit(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="partners_form"
          preserve={false}
          initialValues={{ modifier: 'public' }}
        >
          <Item
            label="Users"
            name="usersList"
            rules={[
              {
                required: true,
                message: 'Please, select at least one user!',
              },
            ]}
          >
            <Select
              loading={fetchingUsers}
              mode="multiple"
              onSearch={(searchValue) => setSearchTerm(searchValue)}
              showSearch
              showArrow={false}
              filterOption={false}
              allowClear={true}
              notFoundContent={
                !debouncedSearchTerm ? null : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }
              placeholder="Search by user phone"
              size="large"
            >
              {debouncedSearchTerm ? (
                fetchingUsers ? (
                  <Option value={null} disabled style={{ textAlign: 'center' }}>
                    <Spin tip="Loading Users..." />
                  </Option>
                ) : (
                  users?.searchUsers.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {user.name}
                    </Option>
                  ))
                )
              ) : null}
            </Select>
          </Item>
        </Form>
      </Modal>

      <Modal
        destroyOnClose
        visible={addCreditVisible}
        title={t('Add Credit to Selected Partner Segment')}
        okText={t('Add Credit')}
        cancelText={t('Cancel')}
        onCancel={() => {
          setAddCreditVisible(false);
        }}
        confirmLoading={addingSegmentCredit}
        onOk={() => {
          addCreditForm
            .validateFields()
            .then((values) => {
              addCreditForm.resetFields();
              onSubmitAddCreditForm(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={addCreditForm}
          layout={isMobile ? 'horizontal' : 'inline'}
          name="credit_form"
          preserve={false}
          initialValues={{ modifier: 'public' }}
        >
          <Item
            label={t('Credit Amount')}
            name="amount"
            rules={[
              {
                required: true,
                message: t('Please, enter the required amount!'),
              },
            ]}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <InputNumber
                placeholder={t('Enter Amount')}
                style={{ width: '150px' }}
              />
              <p
                style={{
                  marginLeft: '5px',
                  marginBottom: '0px',
                  lineHeight: 2,
                }}
              >
                {t('L.E.')}
              </p>
            </div>
          </Item>
        </Form>
      </Modal>
      <Modal
        title={t('Add Export Background Filters')}
        visible={exportModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button type="danger" key="cancel" onClick={handleCancel}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setExportModalVisible(false);
              if (status == 1) {
                handleTransactionsExport(id, from, to);
              } else {
                handleCreditsExport(id, from, to);
              }
              setFrom('');
              setTo('');
              setStatus(0);
            }}
          >
            {t("Submit")}
          </Button>,
        ]}
      >
        <p style={{ color: 'red' }}>
          {t("If you want all records just leave the two input fields empty")}
        </p>
        <label>{t("From Date")}</label>
        <Input
          type="date"
          value={from}
          onChange={(e) => setFrom(e.target.value)}
        />
        <label>{t("To Date")}</label>
        <Input type="date" value={to} onChange={(e) => setTo(e.target.value)} />
      </Modal>
    </SegmentsListContainer>
  );
};

export default SegmentsList;
