/**Component for creating new Location (Station) or editing
 * a previously created station
 */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Row,
  Col,
  Button,
  InputNumber,
  Select,
  Radio,
  PageHeader,
  Spin,
  notification,
} from 'antd';

import {
  CREATE_LOCATION,
  GET_lOCATION,
  UPDATE_LOCATION,
  GET_ACTIVE_CITIES,
  GET_LOCATIONS,
} from 'services';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { notify } from 'utilities';
import { useTranslation } from 'react-i18next';

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 0,
    },
  },
};

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Option = Select.Option;

const LocationForm = ({
  match: {
    path,
    params: { id: locationId },
  },
  form: { validateFields, getFieldDecorator },
  history,
}) => {
  const { data, loading } = useQuery(GET_ACTIVE_CITIES, {});
  const [t,i18n]=useTranslation();
  const nameLang = i18n.language;
  const editMode = path === '/edit-location/:id' ? true : false;
  const locationMutation = editMode ? UPDATE_LOCATION : CREATE_LOCATION;
  const locationMutationName = editMode ? 'updateLocation' : 'createLocation';

  const {
    loading: fetchingLocation,
    data: { location: locationData } = { location: {} },
  } = useQuery(GET_lOCATION, {
    variables: { id: locationId },
    skip: !locationId,
  });

  const [
    processMutation,
    { loading: processingMutation },
  ] = useMutation(locationMutation, { awaitRefetchQueries: true });

  /**Submit the form for creating/updating a station */
  const handleSubmit = (event) => {
    event.preventDefault();
    validateFields((errors, values) => {
      values.city_id = Number(values.city_id);
      if (!errors) {
        processMutation({
          variables: { ...values, id: locationId },
          refetchQueries: () => [
            {
              query: GET_LOCATIONS,
              variables: {
                page: 1,
                first: 5,
                orderBy: 'EN',
              },
            },
          ],
        })
          .then((res) => {
            if (res.data && res.data[locationMutationName].id) {
              notification.success({
                message: `Location ${
                  editMode ? 'updated' : 'created'
                } successfully`,
              });
              history.push('/stations');
            }
          })
          .catch((err) => {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          });
      }
    });
  };

  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title={editMode ? t('Edit Station') : t('Create New Station')}
      />
      {loading && <Spin></Spin>}

      {!fetchingLocation && !loading && data && data.activeCities && (
        <Row>
          <Col span={12}>
            <Form
              layout="horizontal"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item label={t("Code")}>
                    {getFieldDecorator('code', {
                      initialValue: editMode ? locationData.code : undefined,
                      rules: [
                        {
                          message: t('The input is not valid Code!'),
                        },
                        {
                          required: true,
                          message: t('Please input Location Code!'),
                        },
                      ],
                    })(<Input size="large" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={4}>
                <Col span={4}>
                  <Form.Item label={t("City")}>
                    {getFieldDecorator('city_id', {
                      valuePropName: 'option',
                      initialValue:
                        editMode && locationData.city.id
                          ? locationData.city.id
                          : undefined,
                      rules: [
                        {
                          required: true,
                          message: t('Please select City!'),
                        },
                      ],
                    })(
                      <Select
                        placeholder={t("Please select City")}
                        size="large"
                        style={{ width: 200 }}
                        defaultValue={
                          editMode && locationData.city.id
                            ? locationData.city.id
                            : undefined
                        }
                      >
                        {data.activeCities.map((city) => (
                          <Option key={city.id} value={city.id}>
                            {nameLang==="en" ? city.name_en : city.name_ar}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item label={t("English Name")}>
                    {getFieldDecorator('name_en', {
                      initialValue: locationData.name_en
                        ? locationData.name_en
                        : undefined,
                      rules: [
                        {
                          message: t('The input is not valid Name!'),
                        },
                        {
                          required: true,
                          message: t('Please input Location English Name!'),
                        },
                        {
                          min: 3,
                          message: t('English Name must be at least 3 characters'),
                        },
                      ],
                    })(<Input size="large" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t("Arabic Name")}>
                    {getFieldDecorator('name_ar', {
                      initialValue: locationData.name_ar
                        ? locationData.name_ar
                        : undefined,
                      rules: [
                        {
                          message: t('The input is not valid Name!'),
                        },
                        {
                          required: true,
                          message: t('Please input Location Arabic Name!'),
                        },
                        {
                          min: 3,
                          message: t('Arabic Name must be at least 3 characters'),
                        },
                      ],
                    })(<Input size="large" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item {...tailFormItemLayout}>
                <p>{t("Map loaction")}</p>

                <Form.Item label={t("Lat")}>
                  {getFieldDecorator('lat', {
                    initialValue: locationData.lat
                      ? locationData.lat
                      : undefined,
                    rules: [
                      {
                        required: true,
                        message: t('Please input the lat of city!'),
                      },
                    ],
                  })(<InputNumber min={-180} max={180} step={0.1} />)}
                </Form.Item>

                <Form.Item label={t("lng")}>
                  {getFieldDecorator('long', {
                    initialValue: locationData.long
                      ? locationData.long
                      : undefined,
                    rules: [
                      {
                        required: true,
                        message: t('Please input the lng of Location!'),
                      },
                    ],
                  })(<InputNumber min={-90} max={90} step={0.1} />)}
                </Form.Item>
              </Form.Item>
              <Row gutter={4}>
                <Col span={12}>
                  <Form.Item layout="vertical" label={t("type")}>
                    {getFieldDecorator('type', {
                      valuePropName: 'Radio',
                      initialValue: locationData.type
                        ? locationData.type
                        : 'Station',
                    })(
                      <RadioGroup
                        layout="vertical"
                        size="large"
                        defaultValue={
                          locationData.type ? locationData.type : 'Station'
                        }
                      >
                        <RadioButton value="Station">{t("Station")}</RadioButton>
                        <RadioButton value="Stop">{t("Stop")}</RadioButton>
                      </RadioGroup>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item {...tailFormItemLayout}>
                <>
                  <>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 12 }}
                    >
                      {processingMutation && <Spin></Spin>}
                      {editMode ? t(`Save`) : t(`Add`)}
                    </Button>
                    <a href="/stations">{t("Cancel")}</a>
                  </>
                </>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};

LocationForm.propTypes = {
  match: PropTypes.object,
  form: PropTypes.object,
  history: PropTypes.object,
};

const WrappedLocationForm = Form.create({ name: 'location' })(LocationForm);
export default WrappedLocationForm;
