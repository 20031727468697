/**Component that list all orders that were created */
import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Table,
  Button,
  Modal,
  Row,
  Col,
  Radio,
  InputNumber,
  DatePicker,
  Spin,
} from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FETCH_ORDERS, CONFIRM_ORDER } from 'services';
import { notify } from 'utilities';
import { Can, ListHeader, PrimaryTitle } from 'components';
import Filter from './filter';
import styled from '@emotion/styled/macro';
import EXPORT_CASHOUT_REPORT from '../../services/cashout/export-casout-report';
import EXPORT_Orders_REPORT from '../../services/orders/export-orders-report';
import moment from 'moment';
import gql from 'graphql-tag';
import { CancelPendingOrder } from '../../services/orders/cancelPendingOrder';
import { extendOrderExpiration } from '../../services/orders/extendOrderExpiration';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchContainer = styled.div`
  border-bottom: 1px solid #9b9b9b;

  .ant-form,
  .ant-form-inline {
    margin: 20px 0;
  }

  .ant-form label {
    font-size: 16px;
  }
`;

const Orders = ({
  form: {
    getFieldDecorator,
    validateFields,
    resetFields,
    validateFieldsAndScroll,
  },
  location: { search },
}) => {
  const urlParams = new URLSearchParams(search);
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const { merchantRefNumber } =
    urlParams.has('chargeResponse') &&
    JSON.parse(urlParams.get('chargeResponse'));

  useEffect(() => {
    if (merchantRefNumber) history.push(`/order/${merchantRefNumber}`);
  }, [history, merchantRefNumber]);
  const [page, handlePageChange] = useState(1);
  const [order, setOrder] = useState(null);
  const { data, loading, fetchMore, refetch, called } = useQuery(FETCH_ORDERS, {
    variables: {
      page: 1,
      first: 10,
    },
    notifyOnNetworkStatusChange: true,
  });
  const [confirmOrder, { loading: confirmOrderLoading }] = useMutation(
    CONFIRM_ORDER
  );
  const [CancelOder, { loading: cancelOrderLoading }] = useMutation(
    CancelPendingOrder
  );
  const [extendOrderExpirationTime, { loading: extendLoading }] = useMutation(
    extendOrderExpiration
  );
  const [visible, setVisible] = useState(false);
  const [extendFormVisible, setExtendFormVisible] = useState(false);
  const [cancelFormVisible, setCancelFormVisible] = useState(false);
  const [value, setValue] = useState('StoreCash');
  const [filteredValues, setFilteredValues] = useState({
    ticketId: '',
    created_at: '',
    refunded_at: '',
    station: '',
    foundedBy: '',
    confirmed_at_station: '',
  });

  const handleOk = (e, orderNumber) => {
    e.preventDefault();

    validateFieldsAndScroll(
      ['paymentMethod', 'transactionId'],
      (err, values) => {
        if (!err) {
          const { paymentMethod, transactionId } = values;
          confirmOrder({
            variables: {
              orderNumber,
              paymentMethod,
              transactionId,
            },
            refetchQueries: () => [
              {
                query: FETCH_ORDERS,
                variables: {
                  page,
                  first: 10,
                },
              },
            ],
          })
            .then((res) => {
              const {
                data: {
                  confirmStorePayment: { message, status },
                },
              } = res;
              const notificationType = status ? 'success' : 'error';
              notify(notificationType, message);
              resetFields();
              setValue('StoreCash');
              setVisible(false);
            })
            .catch((err) => {
              const {
                extensions: { validation },
                message,
              } = err['graphQLErrors'][0];

              if (validation) {
                for (let error in validation) {
                  notify('error', validation[error][0]);
                }
              } else {
                notify('error', t(message));
              }
            });
        }
      }
    );
  };
  const extendOrder = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { date, time } = values;
        let ext_time = time.format('HH:mm');
        let ext_date = date.format('YYYY-MM-DD');
        extendOrderExpirationTime({
          variables: {
            order_number: order.order_number,
            date: ext_date,
            time: ext_time,
          },
          refetchQueries: () => [
            {
              query: FETCH_ORDERS,
              variables: {
                page,
                first: 10,
              },
            },
          ],
        })
          .then((res) => {
            const {
              data: {
                extendOrderExpiration: { message, status },
              },
            } = res;
            const notificationType = status ? 'success' : 'error';
            notify(notificationType, message);
            resetFields();
            setExtendFormVisible(false);
          })
          .catch((err) => {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];

            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          });
      }
    });
  };

  const [exportReport, { loading: exporting }] = useMutation(
    EXPORT_Orders_REPORT,
    {
      variables: {
        orderNumber: filteredValues.orderNumber || '',
        customerPhone: filteredValues.customerPhone || '',
        status: filteredValues.status || '',
      },
    }
  );
  const columns = [
    {
      title: 'Id',
      dataIndex: 'key',
    },
    {
      title: 'Order Number',
      dataIndex: 'order_number',
    },
    {
      title: 'Line',
      dataIndex: 'tickets',
      key: 'line',
      render: (tickets) =>
        [...new Set(tickets.map((ticket) => ticket.trip?.ref_code))].join(', '),
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'name'],
    },
    {
      title: 'Customer Phone',
      dataIndex: ['customer', 'phone'],
    },
    {
      title: 'Order Price',
      dataIndex: 'price',
    },
    {
      title: 'Order Status',
      // dataIndex: 'status',
      render : (record)=> t(record.status)
    },
    {
      title: 'Purchase Date',
      dataIndex: 'created_at',
    },
    {
      title: 'Payment Method',
      // dataIndex: 'payment_method',
      render: (record)=> t(record.payment_method)
    },
    {
      title: 'Confirm Payment',
      dataIndex: '',
      render: (order) => {
        return order.status === 'Pending' ? (
          <Can
            perform="CONFIRM_ORDER_PAYMENT"
            yes={
              <Button
                onClick={() => {
                  setOrder(order);
                  setVisible(true);
                }}
              >
                {t("Confirm")}
              </Button>
            }
          />
        ) : (
          <></>
        );
      },
    },
    {
      title: 'Extend order time',
      dataIndex: '',
      render: (order) => {
        return order.status === 'Pending' ? (
          <Can
            perform="EXTEND_ORDER_EXPIRATION_TIME"
            yes={
              <Button
                onClick={() => {
                  setOrder(order);
                  setExtendFormVisible(true);
                }}
              >
                {t("Extend")}
              </Button>
            }
          />
        ) : (
          <></>
        );
      },
    },
    {
      title: 'cancel Pending',
      dataIndex: '',
      render: (order) => {
        return order.status === 'Pending' ? (
          <Can
            perform="CancelUnpaidOrder"
            yes={
              <Button
                type="danger"
                onClick={() => {
                  setOrder(order);

                  setCancelFormVisible(true);
                }}
              >
                {t("Cancel")}
              </Button>
            }
          />
        ) : (
          <></>
        );
      },
    },

    {
      title: 'Tranx ID',
      dataIndex: '',
      render: (order) => {
        const tranx =
          order.kiosk_number !== null
            ? order.kiosk_number
            : order.transaction_id;
        return <p>{tranx}</p>;
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      render: (order) => {
        return (
          <Can
            perform="VIEW_ORDER_DETAILS"
            yes={<a href={`/order/${order.key}`}>{t('View Details')}</a>}
          />
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const expandedColumns = [
    {
      title: t('Ticket Codes'),
      dataIndex: '',
      render: (record) => {
        return <p>{record.code}</p>;
      },
    },
  ];
  const handleExport = () => {
    exportReport()
      .then((res) => {
        const {
          data: {
            exportOrdersReport: {
              message,
              status,
              data: { downloadable_link },
            },
          },
        } = res;
        var a = document.createElement('a');
        a.href = downloadable_link;
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
          return;
        }
        notify('error', message);
      });
  };
  return (
    <>
      <ListHeader>
        <PrimaryTitle>{t('Order List')}</PrimaryTitle>
        <div className={'export-button'}>
          <Can
            perform="EXPORT_TICKET_REPORT"
            yes={
              <Button
                className={'ant-btn-danger'}
                size="large"
                loading={exporting}
                onClick={() => handleExport()}
              >
                {t('Export Report')}
              </Button>
            }
          />
        </div>
      </ListHeader>
      <SearchContainer>
        <Filter refetch={refetch} setFilteredValues={setFilteredValues} />
      </SearchContainer>
      <Table
        scroll={{ x: 400 }}
        dataSource={data?.orders?.data
          // https://stackoverflow.com/a/36744732
          .filter((item, index) => {
            return (
              index ===
              data.orders.data.findIndex((obj) => {
                return JSON.stringify(obj) === JSON.stringify(item);
              })
            );
          })
          .map(({ id, ...trip }) => {
            return {
              ...trip,
              key: id,
            };
          })}
        columns={columns}
        loading={loading}
        pagination={{
          total:
            data &&
            data.orders &&
            data.orders.paginatorInfo &&
            data.orders.paginatorInfo.total,
          pageSize: 10,
          showSizeChanger: false,
          onChange: (page) => {
            handlePageChange(page);
            fetchMore({
              variables: {
                page: page,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
              },
            });
          },
        }}
        scroll={{ x: 400 }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              bordered
              columns={expandedColumns}
              dataSource={record?.tickets}
              pagination={false}
              rowKey={(record) => record.id}
            />
          ),
        }}
      />

      <Modal
        visible={visible}
        title="Choose Offline Payment Method ..."
        onFinish={handleOk}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisible(false);
            }}
          >
            {t("Cancel")}
          </Button>,
          <Button
            form="paymentOptionsForm"
            key="submit"
            type="primary"
            disabled={confirmOrderLoading}
            onClick={(e) => {
              handleOk(e, order.order_number);
            }}
          >
            {t("Confirm")}
          </Button>,
        ]}
      >
        <Spin spinning={confirmOrderLoading}>
          <Form id="paymentOptionsForm" layout="vertical">
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator('paymentMethod', {
                    initialValue: 'StoreCash',
                  })(
                    <Radio.Group
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                    >
                      <Radio
                        style={{ marginRight: '120px' }}
                        value={'StoreCash'}
                      >
                        {t("Cash Payment")}
                      </Radio>
                      <Radio value={'StoreCard'}>Credit Card Payment</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label={t("Transaction Number")}>
                  {getFieldDecorator('transactionId', {
                    rules: [
                      {
                        required: value === 'StoreCard' ? true : false,
                        message: 'Please, enter the transaction number!',
                      },
                    ],
                  })(
                    <InputNumber
                      disabled={value === 'StoreCard' ? false : true}
                      style={{ width: '100%' }}
                      size="large"
                      min={0}
                      step={1}
                      placeholder={t("Enter Transaction Number")}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>

      <Modal
        visible={extendFormVisible}
        title="Extend Order Expiration Time"
        onCancel={() => {
          setExtendFormVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setExtendFormVisible(false);
            }}
          >
            {t("Cancel")}
          </Button>,
        ]}
      >
        <Form id="ExtendForm" layout="vertical" onSubmit={extendOrder}>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item>
                {getFieldDecorator('date', {
                  rules: [
                    { required: true, message: 'Please Select a valid date!' },
                  ],
                })(
                  <DatePicker
                    style={{ width: '100%' }}
                    disabledDate={(current) => {
                      return current && current < moment().startOf('day');
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                {getFieldDecorator('time', {
                  rules: [
                    { required: true, message: 'Please Select a valid time!' },
                  ],
                })(
                  <DatePicker
                    picker={'time'}
                    format={'HH:mm'}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Extend Time
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>

      <Spin spinning={cancelOrderLoading}>
        <Modal
          visible={cancelFormVisible}
          title={'Cancel Order #' + order?.order_number}
          onCancel={() => {
            setCancelFormVisible(false);
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setCancelFormVisible(false);
              }}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="danger"
              disabled={cancelOrderLoading}
              onClick={() => {
                CancelOder({
                  variables: {
                    order_number: order.order_number,
                  },
                })
                  .then((res) => {
                    if (res?.data?.AdminCancelUnPaidOrder?.status)
                      notify(
                        'success',
                        res?.data?.AdminCancelUnPaidOrder?.message
                      );
                    else
                      notify(
                        'error',
                        res?.data?.AdminCancelUnPaidOrder?.message
                      );

                    setCancelFormVisible(false);
                    refetch();
                  })
                  .catch((err) => {
                    const {
                      extensions: { validation },
                      message,
                    } = err['graphQLErrors'][0];

                    if (validation) {
                      for (let error in validation) {
                        notify('error', validation[error][0]);
                      }
                    } else {
                      notify('error', message);
                    }

                    // notify('error', 'Order Cannot be Cancelled');
                    setCancelFormVisible(false);
                  });
              }}
            >
              confirm
            </Button>,
          ]}
        >
          Are You Sure You Want To Cancel ?
        </Modal>
      </Spin>
    </>
  );
};

const OrdersFormWrapper = Form.create({})(Orders);

export default OrdersFormWrapper;
