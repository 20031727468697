import gql from 'graphql-tag';

export default gql`
  mutation createPromoCode(
    $value_type: PromoCodeTypes!
    $value: Int!
    $code: String!
    $name: String!
    $seat_type_ids: [ID!]!
    $max_quota: Int!
    $min_tickets: Int!
    $max_tickets: Int!
    $cities: PromoCodeCitiesInput
    $payment_methods: [String]
    $start_date: Date!
    $end_date: Date!
    $trip_end_date: Date!
    $trip_start_date: Date!
    $rounded: Int
    $first_use: Int
    $offline: Int
  ) {
    createPromoCode(
      value_type: $value_type
      value: $value
      code: $code
      name: $name
      seat_type_ids: $seat_type_ids
      max_quota: $max_quota
      cities: $cities
      start_date: $start_date
      end_date: $end_date
      trip_end_date: $trip_end_date
      trip_start_date: $trip_start_date
      rounded: $rounded
      first_use: $first_use
      offline: $offline
      min_tickets: $min_tickets
      max_tickets: $max_tickets
      payment_methods: $payment_methods
    ) {
      status
    }
  }
`;
