/**Component for displaying booked ticket details */
import React , {useEffect} from 'react';
import { Card, Row, Col, Spin } from 'antd';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import { GET_TICKET } from 'services';
import { ListHeader, PrimaryTitle } from 'components';
import { notify } from '../../utilities';
import { useTranslation } from 'react-i18next';

const TicketDetailsContainer = styled.div``;

const TicketDetails = () => {
  const { ticketId } = useParams();
  const [t, i18n] = useTranslation();
  const nameLang = i18n.language;
  const { data, loading } = useQuery(GET_TICKET, {
    variables: {
      id: ticketId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  }); 
  useEffect(() => {
    if (!loading && data === undefined)
        window.location.reload();
}, [loading]);

  return (
    <Spin spinning={loading}>
      <TicketDetailsContainer>
        <ListHeader>
          <PrimaryTitle>{t('Ticket Details')}</PrimaryTitle>
        </ListHeader>

        {data && data.getTicket && (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Card title={t('Customer Info')}>
                  <dl>
                    <dt>{t('Customer Name')}</dt>
                    <dd>{data?.getTicket?.customer?.name}</dd>

                    <dt>{t('Customer Phone')}</dt>
                    <dd>{data?.getTicket?.customer?.phone}</dd>
                  </dl>
                </Card>
              </Col>
              <Col span={12}>
                <Card title={t('Ticket Info')}>
                  <dl>
                    <dt>{t('Ticket Id')}</dt>
                    <dd>{ticketId}</dd>

                    <dt>{t('Ticket Price')}</dt>
                    <dd>{data?.getTicket?.price} L.E.</dd>

                    <dt>{t('Payment Method')}</dt>
                    <dd>{t(data?.getTicket?.payment_method)}</dd>

                    <dt>{t('Purchase Channel')}</dt>
                    <dd>{t(data?.getTicket?.order?.purchase_channel)}</dd>

                    {data?.getTicket?.order?.booked_by && (
                      <>
                        <dt>{t('Booked By')}</dt>
                        <dd>{data?.getTicket?.order?.booked_by?.name}</dd>
                      </>
                    )}
                    {data?.getTicket?.replaced_by && (
                      <>
                        <dt>{t('Replaced By')}</dt>
                        <dd>{data?.getTicket?.replaced_by?.name}</dd>
                      </>
                    )}

                    {data?.getTicket?.order?.paid_by && (
                      <>
                        <dt>{t('Paid By')}</dt>
                        <dd>{data?.getTicket?.order?.paid_by?.name}</dd>
                      </>
                    )}

                    <dt>{t('Ticket Status')}</dt>
                    <dd>{t(data?.getTicket?.status)}</dd>

                    <dt>{t('Created At')}</dt>
                    <dd>{data?.getTicket?.created_at}</dd>

                    <dt>{t('Seat Number')}</dt>
                    <dd>{data?.getTicket?.seat_number}</dd>

                    <dt>{t('Seat Class')}</dt>
                    <dd>{nameLang === "en" ? data?.getTicket?.seat_type?.name_en : data?.getTicket?.seat_type?.name_ar}</dd>
                  </dl>
                </Card>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Card title={t('Trip Info')}>
                  <dl>
                    <dt>{t('Trip Id')}</dt>
                    <dd>{data?.getTicket?.trip?.id}</dd>

                    <dt>{t('Trip Number')}</dt>
                    <dd>{data?.getTicket?.trip?.ref_code}</dd>

                    <dt>{t('From Station')}</dt>
                    <dd>
                      {nameLang === "en" ? data?.getTicket?.from_location?.city?.name_en : data?.getTicket?.from_location?.city?.name_ar} -{' '}
                      {nameLang === "en" ? data?.getTicket?.from_location?.name_en :  data?.getTicket?.from_location?.name_ar} (
                      {moment(
                        data?.getTicket?.from_date +
                          ' ' +
                          data?.getTicket?.from_time
                      ).format('DD MMM YYYY HH:mm')}
                      )
                    </dd>

                    <dt>{t('To Station')}</dt>
                    <dd>
                      {nameLang === "en" ? data?.getTicket?.to_location?.city?.name_en :data?.getTicket?.to_location?.city?.name_ar} -{' '}
                      {nameLang === "en" ? data?.getTicket?.to_location?.name_en : data?.getTicket?.to_location?.name_ar} (
                      {moment(
                        data?.getTicket?.to_date +
                          ' ' +
                          data?.getTicket?.to_time
                      ).format('DD MMM YYYY HH:mm')}
                      )
                    </dd>
                  </dl>
                </Card>
              </Col>
              {data && data.getTicket && data.getTicket.promo_code && (
                <Col span={12}>
                  <Card title={t('Promo-Code Info')}>
                    <dl>
                      <dt>{t('Code Applied')}</dt>
                      <dd>{data.getTicket.promo_code.code}</dd>

                      <dt>{t('Value')}</dt>
                      <dd>
                        {data.getTicket.promo_code.value}{' '}
                        {data.getTicket.promo_code.value_type === 'Percentage'
                          ? '%'
                          : 'L.E.'}
                      </dd>

                      <dt>{t('Value Type')}</dt>
                      <dd>{data.getTicket.promo_code.value_type}</dd>

                      <dt>{t('Original Price')}</dt>
                      <dd>{data?.getTicket?.original_price} L.E.</dd>

                      <dt>{t('New Price')}</dt>
                      <dd>{data?.getTicket?.price} L.E.</dd>

                      <dt>{t('Discount')}</dt>
                      <dd>{data?.getTicket?.discount_cost} L.E.</dd>

                      <dt>{t('Burn Rate')}</dt>
                      <dd>{data?.getTicket?.burn_rate} %</dd>
                    </dl>
                  </Card>
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              {data &&
                data.getTicket &&
                data.getTicket.parent_ticket &&
                !data?.getTicket?.updated_to &&
                !data?.getTicket?.old_ticket && (
                  <Col span={12}>
                    <Card title={t('View Old Tickets')}>
                      <dl>
                        <dt>{t('Old Ticket')}</dt>
                        <dd>
                          <Link
                            to={`/ticket/${data.getTicket.parent_ticket.id}`}
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {t('View Old Ticket')}
                          </Link>
                        </dd>
                      </dl>
                    </Card>
                  </Col>
                )}
              {data?.getTicket?.updated_to && (
                <Col span={12}>
                  <Card title={t('View New Tickets')}>
                    <dl>
                      <dt>{t('New Ticket')}</dt>
                      <dd>
                        <Link
                          to={`/ticket/${data.getTicket.updated_to.id}`}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t('View New Ticket')}
                        </Link>
                      </dd>
                    </dl>
                  </Card>
                </Col>
              )}
              {data?.getTicket?.old_ticket && (
                <Col span={12}>
                  <Card title={t('View Old Tickets')}>
                    <dl>
                      <dt>{t('Old Ticket')}</dt>
                      <dd>
                        <Link
                          to={`/ticket/${data.getTicket.old_ticket.id}`}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t('View Old Ticket')}
                        </Link>
                      </dd>
                    </dl>
                  </Card>
                </Col>
              )}
            </Row>
            <Row>
              {data &&
                data.getTicket &&
                data.getTicket.new_tickets &&
                !data?.getTicket?.updated_to &&
                !data?.getTicket?.old_ticket && (
                  <Col span={12}>
                    <Card title={t('View New Tickets')}>
                      <dl>
                        <dt>{t('New Ticket')}</dt>
                        {data?.getTicket?.new_tickets?.map((ticket, i) => {
                          return (
                            <dd>
                              <Link
                                to={`/ticket/${ticket.id}`}
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                {t('View New Ticket')}
                              </Link>
                            </dd>
                          );
                        })}
                      </dl>
                    </Card>
                  </Col>
                )}
            </Row>
          </>
        )}
      </TicketDetailsContainer>
    </Spin>
  );
};

export default TicketDetails;