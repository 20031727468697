import gql from 'graphql-tag';

const UPDATE_LOCATION = gql`
  mutation updateLocation(
    $id: ID!
    $code: String!
    $name_en: String!
    $name_ar: String!
    $lat: Float!
    $long: Float!
    $type: LocationType!
    $city_id: ID!
  ) {
    updateLocation(
      id: $id
      code: $code
      name_en: $name_en
      name_ar: $name_ar
      lat: $lat
      long: $long
      type: $type
      city_id: $city_id
      is_active: Active
    ) {
      id
      code
      name_en
      name_ar
      lat
      long
    }
  }
`;

export default UPDATE_LOCATION;
