/**Component for ticket booking cycle
 * contain form for selecting/searching for user or creating a new user to book ticket
 * Form for selecting stations and trip date to book ticket
 * Trip Listing table to choose the desired trip
 * choose/book seats on the selected trip
 * Display cart with tickets (selected seats)
 * cart contain actions to remove previously selected seats before order confirmation
 * submit form for booking ticket
 */
import React, { useState, useTransition } from 'react';
import { Row, Col, Modal, Spin } from 'antd';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { notify } from 'utilities';
import SearchTripsForm from './search_trips';
import {
  CreateCart,
  AddCartTickets,
  ReservedTickets,
  RemoveCartTickets,
  CheckEditTicket,
  CheckBulkEditTickets,
} from 'services';
import BusSalon from './bus-salon';
import CustomersWithForm from './customers';
import Trips from './trips';
import Cart from './cart';
import PromoCode from './promo-code';
import { useHistory } from 'react-router-dom';
import { allSeatTypes } from 'services';
import { ListHeader, PrimaryTitle } from 'components';
import { object } from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const TicketingPage = () => {
  const {
    location: { search },
  } = useHistory();
  const urlParams = new URLSearchParams(search);
  const [cartToken, setCartToken] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [rounded, setRounded] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [currentCart, setCurrentCart] = useState(null);

  const [visible, setVisible] = useState(false);
  const [currentTrip, setCurrentTrip] = useState(null);
  const [selectedSeats, setSelectedSeats] = useState({});
  const [selectedSeatsTypes, setSelectedSeatsTypes] = useState({});

  const [selectedSeatsTickets, setSelectedSeatsTickets] = useState({});
  const [editTicket, setEditTicket] = useState(null);
  const [numberOfTickets, setNumberOfTickets] = useState(0);
  const [editBulkTicket, setEditBulkTicket] = useState(false);

  const [addCartTickets, { loading: addCartTicketLoading }] = useMutation(
    AddCartTickets
  );
  const [removeCartTickets, { loading: removeCartTicketLoading }] = useMutation(
    RemoveCartTickets
  );

  const { data: seatTypesData } = useQuery(allSeatTypes, {});

  const [
    getReservedTickets,
    { data: reservedSeats, loading: loadingReservedSeats },
  ] = useLazyQuery(ReservedTickets, { fetchPolicy: 'network-only' });

  const [createCart] = useMutation(CreateCart);
  const [t, i18n] = useTranslation();
  const nameLang = i18n.language;
  const upgrade = urlParams.get('upgrade');

  const { loading } = useQuery(CheckEditTicket, {
    skip: !urlParams.has('ticketId') || urlParams.get('ticketId').includes(','),
    fetchPolicy: 'network-only',
    variables: {
      ticketId: urlParams.get('ticketId'),
      upgrade: upgrade ? true : false,
    },
    onCompleted: (deductionAmountData) => {
      if (deductionAmountData) {
        const {
          getEditTicketDeductionAmount: { status, message, data },
        } = deductionAmountData;

        if (status) {
          notify('success', nameLang ==="en" ? message : t('ticket_notify'));
          const {
            ticket: {
              id: ticketId,
              price: ticketPrice,
              customer,
              from_location_id: fromLocationId,
              to_location_id: toLocationId,
            },
            deduction_amount: deductionAmount,
          } = data;
          console.info(data.ticket);
          selectCustomer(customer);
          setEditTicket({
            ticket_id: ticketId,
            ticket_price: ticketPrice,
            deduction_amount: deductionAmount,
            toLocationId: toLocationId,
            fromLocationId: fromLocationId,
          });
          if (upgrade) {
            let Tdate = data?.ticket?.from_date;
            setFormValues({
              from: fromLocationId,
              to: toLocationId,
              trip_date: Tdate ? moment(Tdate) : null,
            });
          }
        } else notify('error', message);
      }
    },
  });
  const { loading: loadingBulk } = useQuery(CheckBulkEditTickets, {
    skip:
      !urlParams.has('ticketId') || !urlParams.get('ticketId').includes(','),
    fetchPolicy: 'network-only',
    variables: {
      ticketId: urlParams.get('ticketId')
        ? urlParams.get('ticketId').split(',')
        : '',
    },
    onCompleted: (deductionAmountData) => {
      if (deductionAmountData) {
        const {
          checkEditTicket: { status, message, data },
        } = deductionAmountData;

        if (status) {
          notify('success', message);

          const { tickets, deduction_amount: deductionAmount } = data;
          setEditBulkTicket(true);

          let ticketPrice = 0;

          tickets.forEach((ticket) => {
            selectCustomer(ticket.customer);
            ticketPrice += ticket.price;
          });

          setEditTicket({
            ticket_id: urlParams.get('ticketId').split(','),
            ticket_price: ticketPrice,
            deduction_amount: deductionAmount,
          });
        } else notify('error', message);
      }
    },
  });

  if (loading) return <Spin spinning={true} />;
  if (loadingBulk) return <Spin spinning={true} />;

  const fromLocationId = formValues && formValues.from;
  const toLocationId = formValues && formValues.to;

  const restructureTickets = (tripTickets) => {
    const res = tripTickets.reduce((tickets, ticket) => {
      const {
        trip: { id: tripId, date, time },
        id: ticketId,
        from_location:{ name_en: fromLocationName,name_ar: fromLocationName_ar , id: fromLocationId },
        to_location: {name_en: toLocationName,name_ar: toLocationName_ar, id: toLocationId },
        seat_number: seatNumber,
        seat_type: { id: seatTypeId, name_en: SeatTypeName },
        routeLine: { id: routeLineId },
        price,
        original_price,
        from_time,
        from_date,
        to_time,
      } = ticket;

      const groupTicketId = `${routeLineId}_${fromLocationId}_${toLocationId}`;

      tickets[groupTicketId] = tickets[groupTicketId] || {
        fromLocationId,
        fromLocationName,
        fromLocationName_ar,
        toLocationId,
        toLocationName,
        toLocationName_ar,
        tripId,
        routeLineId,
        date,
        time,
        from_time,
        to_time,
        from_date,
        seats: [],
        groupPrice: 0,
        originalGroupPrice: 0,
      };

      tickets[groupTicketId].seats.push({
        ticketId,
        seatTypeId,
        SeatTypeName,
        seatNumber,
        price,
        from_date,
        original_price,
      });

      tickets[groupTicketId].groupPrice += price;
      tickets[groupTicketId].originalGroupPrice =
        original_price && original_price !== price
          ? tickets[groupTicketId].originalGroupPrice + original_price
          : tickets[groupTicketId].originalGroupPrice + price;

      return tickets;
    }, {});
    return res;
  };

  const getIsRoundTrip = () => {
    // TODO: check if there is a more reliable solution
    return rounded;
    /*const { locations } = currentTrip;
    const fromIndex = locations.findIndex((l) => l.id === fromLocationId);
    const toIndex = locations.findIndex((l) => l.id === toLocationId);
    return toIndex < fromIndex;*/
  };

  const handleEditedBulkSeats = (seatNumber) => {
    const roundTrip = getIsRoundTrip();

    const replacedTicketNumber = urlParams.get('ticketId')
      ? urlParams.get('ticketId').split(',').length
      : 0;

    const groupTicketId = `${currentTrip.routeLine?.id}_${
      roundTrip ? formValues.to : formValues.from
    }_${roundTrip ? formValues.from : formValues.to}`;

    if (
      selectedSeats[groupTicketId] &&
      selectedSeats[groupTicketId].includes(seatNumber)
    ) {
      return true;
    }

    if (editBulkTicket && replacedTicketNumber > 0) {
      let selectSeatsNumb = 0;
      Object.values(selectedSeats).forEach((item) => {
        selectSeatsNumb += item.length;
      });

      if (selectSeatsNumb >= replacedTicketNumber) {
        notify('error', `You can only book ${replacedTicketNumber} seats`);
        return false;
      } else {
        return true;
      }
    }
  };

  const selectSeat = (seatNumber, seatTypeId) => {
    if (upgrade && numberOfTickets == 1) {
      notify('error', 'only one seat selected!');
      return;
    }
    const roundTrip = getIsRoundTrip();
    const groupTicketId = `${currentTrip.routeLine?.id}_${
      roundTrip ? formValues.to : formValues.from
    }_${roundTrip ? formValues.from : formValues.to}`;

    const tmpSelectedSeats = { ...selectedSeats };

    const tmpSelectedSeatsTypes = { ...selectedSeatsTypes };

    if (!tmpSelectedSeats.hasOwnProperty(groupTicketId))
      tmpSelectedSeats[groupTicketId] = [];
    if (!tmpSelectedSeatsTypes.hasOwnProperty(groupTicketId))
      tmpSelectedSeatsTypes[groupTicketId] = [];

    tmpSelectedSeats[groupTicketId].push(seatNumber);
    tmpSelectedSeatsTypes[groupTicketId].push(seatTypeId);

    setSelectedSeats(tmpSelectedSeats);
    setSelectedSeatsTypes(tmpSelectedSeatsTypes);
    setNumberOfTickets(numberOfTickets + 1);
  };

  const removeSelectedSeat = (seatNumber, ticketGroupId) => {
    const tmpSelectedSeats = { ...selectedSeats };
    const tmpSelectedSeatsTypes = { ...selectedSeatsTypes };
    const tmpSelectedSeatsTickets = { ...selectedSeatsTickets };

    if (!tmpSelectedSeats.hasOwnProperty(ticketGroupId))
      tmpSelectedSeats[ticketGroupId] = [];
    if (!tmpSelectedSeatsTypes.hasOwnProperty(ticketGroupId))
      tmpSelectedSeatsTypes[ticketGroupId] = [];
    if (!tmpSelectedSeatsTickets.hasOwnProperty(ticketGroupId))
      tmpSelectedSeatsTickets[ticketGroupId] = [];

    const keyIndex = tmpSelectedSeats[ticketGroupId].indexOf(seatNumber);

    const ticketIdToRemove = tmpSelectedSeatsTickets[ticketGroupId][keyIndex];
    if (ticketIdToRemove) {
      removeCartTickets({
        variables: {
          ticketIds: ticketIdToRemove,
          cartToken,
        },
      })
        .then((response) => {
          const {
            data: { removeCartTickets },
          } = response;
          tmpSelectedSeats[ticketGroupId].splice(keyIndex, 1);
          tmpSelectedSeatsTypes[ticketGroupId].splice(keyIndex, 1);
          tmpSelectedSeatsTickets[ticketGroupId].splice(keyIndex, 1);
          setSelectedSeats(tmpSelectedSeats);
          setSelectedSeatsTypes(tmpSelectedSeatsTypes);
          setSelectedSeatsTickets(tmpSelectedSeatsTickets);
          setCurrentCart(removeCartTickets);
          setNumberOfTickets(numberOfTickets - 1);
        })
        .catch((err) => {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];

          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        });
    }
  };

  const addSelectedSeatsToCart = () => {
    const roundTrip = getIsRoundTrip();
    const groupTicketId = `${currentTrip.routeLine?.id}_${
      roundTrip ? formValues.to : formValues.from
    }_${roundTrip ? formValues.from : formValues.to}`;
    const tmpSelectedSeatsTickets = { ...selectedSeatsTickets };

    if (!tmpSelectedSeatsTickets.hasOwnProperty(groupTicketId))
      tmpSelectedSeatsTickets[groupTicketId] = [];

    let ticketsToAdd = [];
    if (selectedSeats[groupTicketId]) {
      selectedSeats[groupTicketId].forEach((seat, index) => {
        ticketsToAdd.push({
          trip_id: currentTrip.id,
          trip_route_line_id: currentTrip.routeLine.id,
          from_location_id: roundTrip ? toLocationId : fromLocationId,
          to_location_id: roundTrip ? fromLocationId : toLocationId,
          seat_number: seat,
          seat_type_id: selectedSeatsTypes[groupTicketId][index],
          customer_id: selectedCustomer.id,
        });
      });

      let one_of_edit_ticket_id = 0;
      let edit_ticket_in_search_query = urlParams.get('ticketId');
      if (urlParams.has('ticketId')) {
        if (edit_ticket_in_search_query.includes(',')) {
          const [first, ...rest] = edit_ticket_in_search_query.split(',');
          console.info(first);
          console.info(rest);
          one_of_edit_ticket_id = first;
        } else one_of_edit_ticket_id = edit_ticket_in_search_query;
      }
      addCartTickets({
        variables: {
          cartToken,
          tickets: ticketsToAdd,
          upgrade: upgrade ? editTicket?.ticket_price : 0,
          edit_id: one_of_edit_ticket_id,
        },
      })
        .then((response) => {
          const {
            data: {
              addCartTickets: { message_ar, message, status, cart },
            },
          } = response;
          if (status) {
            if (currentCart && currentCart.promo_code)
              cart.promo_code = currentCart.promo_code;

            setCurrentCart(cart);

            const ticketsPerTrip = restructureTickets(cart.tickets);
            const tripDetails = ticketsPerTrip[groupTicketId];

            tripDetails.seats.forEach((seat) => {
              tmpSelectedSeatsTickets[groupTicketId].push(seat.ticketId);
            });

            setSelectedSeatsTickets(tmpSelectedSeatsTickets);
          } else {
            setSelectedSeatsTypes({});
            setSelectedSeats({});
            setNumberOfTickets(numberOfTickets - 1);
            notify('error', message);
          }
        })
        .catch((err) => {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];

          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        });
    }
  };

  const showModal = ({ id: tripId }) => {
    setVisible(true);
    const reservedFrom = rounded ? toLocationId : fromLocationId;
    const reservedTo = rounded ? fromLocationId : toLocationId;
    getReservedTickets({
      variables: {
        tripId,
        fromLocationId: reservedFrom,
        toLocationId: reservedTo,
      },
    });
  };
  const handleCancel = () => {
    setVisible(false);
    setSelectedSeats({});
    setSelectedSeatsTypes({});
  };
  const exit_modal = () => {
    setVisible(false);
  };

  const selectCustomer = (selectedCustomerStringified) => {
    const selectedCustomer =
      typeof selectedCustomerStringified === 'string'
        ? JSON.parse(selectedCustomerStringified)
        : selectedCustomerStringified;
    createCart()
      .then((response) => {
        // This should return the created cart id/token.
        const {
          data: {
            createCart: { token: cartToken },
          },
        } = response;
        setSelectedCustomer(selectedCustomer);
        setCartToken(cartToken);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
  };

  return (
    <>
      <Spin spinning={removeCartTicketLoading}>
        <ListHeader>
          <PrimaryTitle>
            {upgrade
              ? t('Upgrade Seat: ') + editTicket?.ticket_price + ' EGP'
              : !editTicket
              ? t('New Ticket')
              : t('Edit Ticket')}
          </PrimaryTitle>
        </ListHeader>
        {selectedCustomer && selectedCustomer.id ? (
          <h2>{`${selectedCustomer.name}: ${selectedCustomer.phone}`}</h2>
        ) : (
          <CustomersWithForm selectCustomer={selectCustomer} />
        )}
        {selectedCustomer && !upgrade && (
          <SearchTripsForm
            setFormValues={setFormValues}
            setRounded={setRounded}
          />
        )}
        {formValues && (
          <Trips
            setRounded={setRounded}
            formValues={formValues}
            showModal={showModal}
            setCurrentTrip={setCurrentTrip}
          />
        )}
        {currentTrip && (
          <>
            <Modal
              title={` ${t("Select Your Seats for trip")} ${currentTrip.ref_code}`}
              visible={visible}
              onOk={() => {
                setVisible(false);
                addSelectedSeatsToCart();
              }}
              confirmLoading={addCartTicketLoading || removeCartTicketLoading}
              onCancel={handleCancel}
              maskClosable={false}
            >
              <Row>
                <Col span={18}>
                  <Spin
                    spinning={
                      loadingReservedSeats ||
                      addCartTicketLoading ||
                      removeCartTicketLoading
                    }
                  >
                    <BusSalon
                      formValues={formValues}
                      trip={currentTrip}
                      selectSeat={selectSeat}
                      handleEditedBulkSeats={handleEditedBulkSeats}
                      exit_modal={exit_modal}
                      allSeatTypes={seatTypesData.allSeatTypes}
                      reservedSeats={
                        reservedSeats && reservedSeats.tripReservedSeats
                          ? reservedSeats.tripReservedSeats.reserved_seats
                          : []
                      }
                      selectedSeats={
                        (currentTrip &&
                          selectedSeats[currentTrip.groupTicketId]) ||
                        []
                      }
                      editBulkTicket={editBulkTicket}
                    />
                  </Spin>
                </Col>
                <Col span={6}>
                  <p>{t("Seat Pricing")}:</p>
                  {currentTrip &&
                    currentTrip.routeLine &&
                    currentTrip.routeLine.prices &&
                    currentTrip.routeLine.prices.length &&
                    currentTrip.routeLine.prices.map((seatPricing, index) => (
                      <p key={index}>
                        {nameLang === "en" ? seatPricing.seat_type.name_en : seatPricing.seat_type.name_ar}: {seatPricing.price}
                      </p>
                    ))}
                </Col>
              </Row>
            </Modal>
            {currentCart && (
              <>
                <PromoCode
                  cartToken={currentCart.token}
                  currentCart={currentCart}
                  setCurrentCart={setCurrentCart}
                  currentPromoCode={currentCart && currentCart.promo_code}
                  selectedCustomer={selectedCustomer}
                  restructureTickets={restructureTickets}
                />

                <Cart
                  currentCart={currentCart}
                  cartToken={cartToken}
                  selectedCustomer={selectedCustomer}
                  restructureTickets={restructureTickets}
                  setCurrentCart={setCurrentCart}
                  removeSelectedSeat={removeSelectedSeat}
                  editTicket={editTicket}
                  setEditTicket={setEditTicket}
                  allSeatTypes={seatTypesData.allSeatTypes}
                  currentPromoCode={currentCart && currentCart.promo_code}
                  bulk={editBulkTicket}
                  setSelectedSeats={setSelectedSeats}
                  upgrade={upgrade ?? false}
                />
              </>
            )}
          </>
        )}
      </Spin>
    </>
  );
};

export default TicketingPage;
